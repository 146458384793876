import React, { ChangeEvent, MouseEvent, useEffect, useState } from "react";
import { Modal } from "../modal";
import { Grid, Stack, Typography, Container, TextField, Checkbox, Button, IconButton } from "@mui/material";

import { NumberFormatBase } from 'react-number-format';
import HorizontalRuleIcon from '@mui/icons-material/HorizontalRule';
import DeleteIcon from '@mui/icons-material/Delete';
import { Intervals } from "../../types/guardian-types";

export const diasSemana = [
  { name: 'Domingo', value: 'isSunday' },
  { name: 'Segunda', value: 'isMonday' },
  { name: 'Terça', value: 'isTuesday' },
  { name: 'Quarta', value: 'isWednesday' },
  { name: 'Quinta', value: 'isThursday' },
  { name: 'Sexta', value: 'isFriday' },
  { name: 'Sábado', value: 'isSaturday' },
]
interface CustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
}

interface Props {
  intervals: Intervals[];
  open: boolean;
  selectedDay: string;
  handleClose: (isEntry: boolean) => void;
  handleChangeInterval: (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, id: number, isEntry: boolean, selectedDay: string) => void;
  handleMultipleChanges: (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, value: boolean, isAll: boolean, isEntry: boolean, selectedDay: string) => void;
  removerPeriodo: (e: MouseEvent, id: number) => void;
  adicionarPeriodo: (e: MouseEvent, isEntrada: boolean, selectedDay: string) => void;
  isEntrada: boolean;
}

const HorarioInputs = (props: Props) => {
  const { intervals, open, handleClose, isEntrada, handleChangeInterval, selectedDay, handleMultipleChanges, adicionarPeriodo, removerPeriodo } = props;
  const [openModal, setOpenModal] = useState(false);

  const isKeySelected = (intervals: Intervals[], key: string = '', isAll: boolean = false) : boolean => {
    if (isAll) {
      let isEveryKeyTrue = true
      diasSemana.forEach((dia) => {
        intervals && intervals.every((interval) => {
          if (interval[dia.value] === false) {
            return isEveryKeyTrue = false
          } else {
            return true
          }
        })
      });
      return isEveryKeyTrue;
    } else {
      return intervals && intervals.every((interval) => interval[key] === true) 
    }
  }

  const formatValue = (value: string) => {
    if (value.length === 1) {
      value = `0${value}:00:00`
    }
    if (value.length === 2) {
      value = `${value}:00:00`
    }

    return value;
  }

  useEffect(() => {
    setOpenModal(open)
  }, [open, intervals])

  const TimeFormatCustom = (props: any) => {
    const format = (val: any) => {
      if (!val) return '';

      const sanitizedValue = val.replace(/[^\d]/g, '');
      let hours = sanitizedValue.substring(0, 2);
      let minutes = sanitizedValue.substring(2, 4);
      let seconds = sanitizedValue.substring(4, 6);

      if (sanitizedValue.length > 2) {
        hours = Math.min(parseInt(hours), 23).toString().padStart(2, '0');
        if (sanitizedValue.length > 3) {
          if (minutes.length > 2 || minutes[0] > 5) {
            minutes = Math.min(parseInt(minutes), 59).toString().padStart(2, '0');
          }
          if (sanitizedValue.length > 5) {
            if (seconds.length > 2 || seconds[0] > 5) {
              seconds = Math.min(parseInt(seconds), 59).toString().padStart(2, '0');
            }
            seconds = Math.min(parseInt(seconds), 59).toString().padStart(2, '0');
          }
        }
        return `${hours}:${minutes}`;
      }
      return sanitizedValue;
    };

    return <NumberFormatBase {...props} format={format} />;
  }

  return (
    <Modal
      open={openModal}
      onClose={() => handleClose(isEntrada)}
      title="Configuração de períodos:"
      disableExpand
      maxWidth={600}
    >
      <Container
        maxWidth="md"
        sx={{
          mt: 2,
          overflowY: 'auto',
          maxHeight: 'calc(100vh - 150px)',
        }}
      >
        <Grid
          container
          justifyContent='flex-end'
        >
          <Grid
            item
            xs={12}
          >
            <Stack
              alignItems="flex-start"
              direction="column"
              justifyContent="space-between"
              sx={{
                mb: 2
              }}
            >
              <Stack
                direction="row"
              >
                <Typography
                  variant="h6"
                >
                  Horário de {isEntrada ? 'Entrada' : 'Saída'}:
                </Typography>
              </Stack>
            </Stack>
            {intervals.map((interval, index) => (
              <Stack
                direction="row"
                flexWrap="wrap"
                sx={{
                  backgroundColor: 'rgba(0, 0, 0, 0.1)',
                  borderRadius: 1,
                  p: 2,
                  mb: 2
                }}
              >
                <Stack
                  key={index}
                  direction="row"
                  spacing={2}
                  sx={{
                    width: '100%',
                    alignItems: "center",
                    mb: 2
                  }}
                >
                  <Typography
                    variant="h6"
                    sx={{
                      textWrap: 'nowrap'
                    }}
                  >
                    Período {index + 1}:
                  </Typography>
                  <TextField
                    label="De:"
                    value={interval.startTime}
                    onChange={(event) => handleChangeInterval(event, interval.id, isEntrada, selectedDay)}
                    onBlur={(event) => {
                      event.target.value = formatValue(event.target.value)
                      handleChangeInterval(event, interval.id, isEntrada, selectedDay)
                    }}
                    name="startTime"
                    InputProps={{
                      inputComponent: TimeFormatCustom,
                    }}
                    variant="standard"
                  />
                  <HorizontalRuleIcon />
                  <TextField
                    label="Até:"
                    value={interval.endTime}
                    onChange={(event) => handleChangeInterval(event, interval.id, isEntrada, selectedDay)}
                    onBlur={(event) => {
                      event.target.value = formatValue(event.target.value)
                      handleChangeInterval(event, interval.id, isEntrada, selectedDay)
                    }}
                    name="endTime"
                    InputProps={{
                      inputComponent: TimeFormatCustom,
                    }}
                    variant="standard"
                  />
                  <IconButton
                    onClick={(e) => removerPeriodo(e, interval.id)}
                    sx={{
                      width: 'unset'
                    }}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Stack>
              </Stack>
            ))}
          </Grid>
          {intervals.length < 4 && (
            <Grid
              item
              xs={12}
              md={4}
              display="flex"
              sx={{
                mb: 3
              }}
            >
              <Button
                variant="contained"
                color="primary"
                onClick={(event) => adicionarPeriodo(event, isEntrada, selectedDay )}
                sx={{
                  mt: 2
                }}
              >
                Adicionar Período
              </Button>
            </Grid>
          )}
          {intervals && intervals.length > 0 && (
            <Grid
              item
              xs={12}
              sx={{
                flexDirection: 'row',
              }}
            >
              <Stack
                alignItems="flex-start"
                direction="column"
                justifyContent="space-between"
                sx={{
                  mb: 2
                }}
              >
                <Typography
                  variant="h6"
                >
                  Ajustar Globalmente
                </Typography>
              </Stack>
              <Stack
                direction="row"
                flexWrap="wrap"
                sx={{
                  mb: 2,
                }}
              >
                {diasSemana.map((dia, index) => {
                  const isChecked = isKeySelected(intervals, dia.value, false)
                  return (
                  <Stack
                    key={index}
                    direction="row"
                    sx={{
                      alignItems: "center",
                      mr: 1,
                      minWidth: 115
                    }}
                  >
                    <Checkbox
                      checked={isChecked}
                      onChange={(event) => handleMultipleChanges(event, !isChecked, false, isEntrada, selectedDay)}
                      name={dia.value}
                      sx={{
                        margin: '0 !important'
                      }}
                    />
                    <Typography
                      variant="body1"
                      sx={{
                        textWrap: 'nowrap'
                      }}
                    >
                      {dia.name}
                    </Typography>
                  </Stack>
                )})}
                <Stack
                  direction="row"
                  sx={{
                    alignItems: "center"
                  }}
                >
                  <Checkbox
                    checked={isKeySelected(intervals, '', true)}
                    onChange={(event) => handleMultipleChanges(event, !isKeySelected(intervals, '', true), true, isEntrada, selectedDay)}
                    name={'isAll'}
                    sx={{
                      margin: '0 !important'
                    }}
                  />
                  <Typography
                    variant="body1"
                    sx={{
                      textWrap: 'nowrap'
                    }}
                  >
                    Todos
                  </Typography>
                </Stack>
              </Stack>
            </Grid>
          )}
        </Grid>
      </Container>
    </Modal>
  );
}

export default HorarioInputs;