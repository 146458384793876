import React, {ChangeEvent, useState} from "react";
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import * as Yup from 'yup';
import {useFormik} from 'formik';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Unstable_Grid2';
import TextField from '@mui/material/TextField';
import {
  Autocomplete,
  Divider,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Grow,
  InputAdornment,
  MenuItem,
  Switch
} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import SaveIcon from '@mui/icons-material/Save';
import {pt} from 'yup-locale-pt';
import {ExibeMensagem} from "../../components/shared/Message";
import {Matricula} from "../../components/MatriculaAutocompleteSelect/MatriculaAutocompleteSelect";
import {Unidade} from "../../components/UnidadeAutocompleteSelect/UnidadeAutocompleteSelect";
import withUnidade from "../../hocs/withUnidade";
import Slider from '@mui/material/Slider';
import Input from "@mui/material/Input";
import {gql, useMutation, useQuery} from "@apollo/client";
import {
  CadastroServicosComplementaresRequest,
  CadastroServicosComplementaresResponse
  //@ts-ignore
} from "seb-graph-api-types/generated";
import {darken, lighten, styled} from "@mui/system";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import CustomTable from "../../components/CustomTable";
import {AuthContext} from "../../context/AuthContext";

Yup.setLocale(pt);

const tabs = {
  CREATE: "CREATE",
  LIST: "LIST"
}

const GroupHeader = styled('div')(({ theme }) => ({
  position: 'sticky',
  top: '-8px',
  padding: '4px 10px',
  color: theme.palette.primary.main,
  backgroundColor:
    theme.palette.mode === 'light'
      ? lighten(theme.palette.primary.light, 0.85)
      : darken(theme.palette.primary.main, 0.8),
}));

const GroupItems = styled('ul')({
  padding: 0,
});

const requiredLabel = "Este campo é obrigatório";

const validationSchema = Yup.object<CadastroServicosComplementaresRequest>({
  unidade_Id: Yup.string().required(requiredLabel),
  nome: Yup.string().required(requiredLabel),
  tipoItem: Yup.string().required(requiredLabel),
  maxParcelas: Yup.number().nullable(),
  periodo_Value: Yup.number().required(requiredLabel),
  anoLetivo_Id: Yup.string().required(requiredLabel),
  valorBase: Yup.number().required(requiredLabel),
  descontoAvista: Yup.number().nullable(),
  dataInicioAula: Yup.string().required(requiredLabel),
  dataInicioVigencia: Yup.string().required(requiredLabel),
  dataTerminoVigencia: Yup.string().required(requiredLabel),
  dataCalculoProporcionalidade: Yup.string().required(requiredLabel),
  considerarProporcionalidade: Yup.boolean().nullable(),
  permiteIsencaoMulta: Yup.boolean().nullable(),
  quantidadeTurmas: Yup.number().min(1).required(requiredLabel),
  quantidadeVagasPorTurmas: Yup.number().min(1).required(requiredLabel),
  relacionamentoServico: Yup.string().nullable(),
  contratacaoObrigatoria: Yup.boolean().required(requiredLabel)
})

const initialValues: CadastroServicosComplementaresRequest = {
  unidade_Id: null,
  nome: '',
  tipoItem: '',
  maxParcelas: 1,
  periodo_Value: 0,
  anoLetivo_Id: '',
  valorBase: 0,
  descontoAvista: 0,
  dataInicioAula: '',
  dataInicioVigencia: '',
  dataTerminoVigencia: '',
  dataCalculoProporcionalidade: '',
  considerarProporcionalidade: false,
  permiteIsencaoMulta: false,
  quantidadeTurmas: 0,
  quantidadeVagasPorTurmas: 0,
  relacionamentoServico: '',
  contratacaoObrigatoria: false
};

interface Props {
  Matricula: Matricula,
  Unidade: Unidade
  handleShowCsat: (key: string) => void
}

function ServicosComplementares(props: Props) {
  const {
    Unidade,
  } = props;
  const {
    data: queryResp,
    loading,
    refetch
  } = useQuery<{cadastroServicosComplementaresPage: CadastroServicosComplementaresResponse}>(gql`
    query CadastroServicosComplementaresPage($filter: CadastroServicosComplementaresFilter) {
      cadastroServicosComplementaresPage(filter: $filter) {
        anosLetivos {
          id
          codigo
        }
        servicosRegulares {
          id
          nome
          nomeLandingPage
          anoLetivo_Name
        }
        tiposItemServico {
          descricao
          valor
        }
        servicosPendentes {
          id
          nome
          razaoStatus
          anoLetivo_Name
          tipoItem {
            descricao
            valor
          }
        }
      }
    }
  `, {
    variables: {
      filter: {
        unidadeId: Unidade?.id
      }
    }
  })
  const [
    saveMutation,
    {
      loading: saving
    }
  ] = useMutation<{cadastroServicosComplementaresPage: string}>(gql`
    mutation CadastroServicosComplementaresPage($data: CadastroServicosComplementaresRequest) {
      cadastroServicosComplementaresPage(data: $data)
    }
  `)
  const [refetching, setRefetching] = useState(false)
  const [selected, setSelected] = useState([])
  const data = queryResp?.cadastroServicosComplementaresPage;
  const [tab, setTab] = useState(tabs.CREATE);
  const options = (data?.servicosRegulares || []).filter(row => parseInt(row?.anoLetivo_Name) >= new Date().getFullYear())
  const canEditFoto = true
  const readOnlyFields = false;
  const readOnlyPhoto = false;
  const readOnly = readOnlyFields && readOnlyPhoto;
  const disabledFields = false;
  const formik = useFormik({
    initialValues: {
      ...initialValues,
      unidade_Id: Unidade.id,
    },
    validationSchema,
    enableReinitialize: true,
    onSubmit: async (values, helpers): Promise<void> => {
      try {
        if (saving) return;

        const {data} = await saveMutation({
          variables: {
            data: values
          }
        });

        setRefetching(true);
        await refetch();
        setRefetching(false);

        helpers.resetForm();
        setSelected([data.cadastroServicosComplementaresPage]);
        setTab(tabs.LIST);

        ExibeMensagem("Serviço cadastrado com sucesso!", "0", true);
      } catch (err) {
        console.error(err);
      }
    }
  });
  const tableConfigs = {
    selection: true,
    editing: false,
    isEndedList: true,
    actions: [
    ],
    onClickAction: (action, row) => {
    },
  }

  const handleTabsChange = (event: ChangeEvent<any>, value: string): void => {
    setTab(value);
  }

  if (loading || refetching)
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%',
          height: '300px'
        }}
      >
        <CircularProgress/>
      </Box>
    )

  return (
    <React.Fragment>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
        }}
      >
        <form
          onSubmit={formik.handleSubmit}
        >
          <Stack
            spacing={3}
            sx={{ mb: 3 }}
          >
            <div>
              <Typography
                variant="h4"
                sx={{
                  mb: 3
                }}
              >
                Cadastro Serviço Complementar
              </Typography>

              <Tabs
                indicatorColor="primary"
                onChange={handleTabsChange}
                scrollButtons="auto"
                textColor="primary"
                value={tab}
                variant="scrollable"
              >
                <Tab
                  label="Novo serviço"
                  value={tabs.CREATE}
                />
                <Tab
                  label="Serviços em validação"
                  value={tabs.LIST}
                />
              </Tabs>
              <Divider />
            </div>
          </Stack>

          {tab === tabs.CREATE &&
            <Stack spacing={3}>
              <Stack
                spacing={4}
                useFlexGap
                flexWrap="wrap"
              >
                <Grid
                  container
                  spacing={4}
                  width="100%"
                  justifyContent="flex-start"
                >
                  <Grow
                    in
                    easing="ease-out"
                    mountOnEnter
                    unmountOnExit
                    timeout={800}
                  >
                    <Grid
                      md={6}
                      width="100%"
                    >
                      <Stack
                        direction={{md: 'row', sm: 'column'}}
                        spacing={3}
                        sx={{
                          mb: 3
                        }}
                      >
                        <TextField
                          error={!!(formik.touched.tipoItem && formik.errors.tipoItem)}
                          fullWidth
                          helperText={String((formik.touched.tipoItem && formik.errors.tipoItem) ?? "")}
                          label="Tipo de serviço"
                          name="tipoItem"
                          size="small"
                          onBlur={formik.handleBlur}
                          onChange={formik.handleChange}
                          value={formik.values.tipoItem}
                          disabled={disabledFields}
                          variant={readOnlyFields ? "standard" : undefined}
                          inputProps={
                            {readOnly: readOnlyFields,}
                          }
                          select
                        >
                          {data?.tiposItemServico?.map((row, i) => (
                            <MenuItem
                              key={row?.valor ?? i}
                              value={row?.valor ?? i}
                            >
                              {row?.descricao}
                            </MenuItem>
                          ))}
                        </TextField>
                      </Stack>

                      <Stack
                        direction={{md: 'row', sm: 'column'}}
                        spacing={3}
                        sx={{
                          mb: 3
                        }}
                      >
                        <TextField
                          error={!!(formik.touched.anoLetivo_Id && formik.errors.anoLetivo_Id)}
                          fullWidth
                          helperText={String((formik.touched.anoLetivo_Id && formik.errors.anoLetivo_Id) ?? "")}
                          label="Ano letivo"
                          name="anoLetivo_Id"
                          size="small"
                          onBlur={formik.handleBlur}
                          onChange={formik.handleChange}
                          value={formik.values.anoLetivo_Id}
                          disabled={disabledFields}
                          variant={readOnlyFields ? "standard" : undefined}
                          inputProps={
                            {readOnly: readOnlyFields,}
                          }
                          select
                        >
                          {(data?.anosLetivos || []).filter(r => parseInt(r.codigo) >= new Date().getFullYear())?.map((row, i) => (
                            <MenuItem
                              key={row?.id ?? i}
                              value={row?.id ?? i}
                            >
                              {row?.codigo}
                            </MenuItem>
                          ))}
                        </TextField>
                      </Stack>

                      <Stack
                        direction={{md: 'row', sm: 'column'}}
                        spacing={3}
                        sx={{
                          mb: 3
                        }}
                      >
                        <TextField
                          error={!!(formik.touched.periodo_Value && formik.errors.periodo_Value)}
                          fullWidth
                          helperText={String((formik.touched.periodo_Value && formik.errors.periodo_Value) ?? "")}
                          label="Período"
                          name="periodo_Value"
                          size="small"
                          onBlur={formik.handleBlur}
                          onChange={formik.handleChange}
                          value={formik.values.periodo_Value}
                          disabled={disabledFields}
                          variant={readOnlyFields ? "standard" : undefined}
                          inputProps={
                            {readOnly: readOnlyFields,}
                          }
                          select
                        >
                          <MenuItem value={1}>Manhã</MenuItem>
                          <MenuItem value={2}>Tarde</MenuItem>
                          <MenuItem value={3}>Noite</MenuItem>
                          <MenuItem value={4}>Integral</MenuItem>
                          <MenuItem value={5}>Semi Integral</MenuItem>
                        </TextField>
                      </Stack>

                      <Stack
                        direction={{md: 'row', sm: 'column'}}
                        spacing={3}
                        sx={{
                          mb: 3
                        }}
                      >
                        <TextField
                          error={!!(formik.touched.nome && formik.errors.nome)}
                          fullWidth
                          helperText={String((formik.touched.nome && formik.errors.nome) ?? "")}
                          label="Nome"
                          name="nome"
                          size="small"
                          onBlur={formik.handleBlur}
                          onChange={formik.handleChange}
                          value={formik.values.nome}
                          disabled={disabledFields}
                          variant={readOnlyFields ? "standard" : undefined}
                          inputProps={
                            {readOnly: readOnlyFields,}
                          }
                        />
                      </Stack>

                      <Stack
                        direction={{md: 'row', sm: 'column'}}
                        spacing={3}
                        sx={{
                          mb: 3
                        }}
                      >
                        <TextField
                          error={!!(formik.touched.valorBase && formik.errors.valorBase)}
                          fullWidth
                          helperText={String((formik.touched.valorBase && formik.errors.valorBase) ?? "")}
                          label="Valor base"
                          name="valorBase"
                          type="number"
                          size="small"
                          onBlur={formik.handleBlur}
                          onChange={formik.handleChange}
                          value={formik.values.valorBase}
                          disabled={disabledFields}
                          variant={readOnlyFields ? "standard" : undefined}
                          InputProps={
                            {
                              readOnly: readOnlyFields,
                              startAdornment: <InputAdornment position="start">R$</InputAdornment>
                            }
                          }
                        />
                      </Stack>

                      <Stack
                        direction={{md: 'row', sm: 'column'}}
                        spacing={3}
                        sx={{
                          mb: 3
                        }}
                      >
                        <TextField
                          error={!!(formik.touched.quantidadeTurmas && formik.errors.quantidadeTurmas)}
                          fullWidth
                          helperText={String((formik.touched.quantidadeTurmas && formik.errors.quantidadeTurmas) ?? "")}
                          label="Quantidade de turmas"
                          name="quantidadeTurmas"
                          type="number"
                          size="small"
                          onBlur={formik.handleBlur}
                          onChange={formik.handleChange}
                          value={formik.values.quantidadeTurmas}
                          disabled={disabledFields}
                          variant={readOnlyFields ? "standard" : undefined}
                          inputProps={
                            {readOnly: readOnlyFields,}
                          }
                        />

                        <TextField
                          error={!!(formik.touched.quantidadeVagasPorTurmas && formik.errors.quantidadeVagasPorTurmas)}
                          fullWidth
                          helperText={String((formik.touched.quantidadeVagasPorTurmas && formik.errors.quantidadeVagasPorTurmas) ?? "")}
                          label="Quantidade de vagas por turma"
                          name="quantidadeVagasPorTurmas"
                          type="number"
                          size="small"
                          onBlur={formik.handleBlur}
                          onChange={formik.handleChange}
                          value={formik.values.quantidadeVagasPorTurmas}
                          disabled={disabledFields}
                          variant={readOnlyFields ? "standard" : undefined}
                          inputProps={
                            {readOnly: readOnlyFields,}
                          }
                        />
                      </Stack>

                      <Stack
                        direction={{md: 'row', sm: 'column'}}
                        spacing={3}
                        sx={{
                          mb: 3
                        }}
                      >
                        <Autocomplete
                          fullWidth
                          options={options}
                          onBlur={formik.handleBlur}
                          getOptionLabel={(option) => option?.nomeLandingPage || option?.nome}
                          isOptionEqualToValue={(option, value) => option?.id === value?.id}
                          onChange={(event, newValue) => {
                            formik.setFieldValue('relacionamentoServico', newValue?.id ?? "");
                            return newValue
                          }}
                          value={options.find(o => o.id === formik.values.relacionamentoServico)}
                          filterSelectedOptions
                          size="small"
                          groupBy={(option) => option?.anoLetivo_Name?.toUpperCase()}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Vincular ao curso regular"
                              placeholder="Selecione um curso"
                              error={!!(formik.touched.relacionamentoServico && formik.errors.relacionamentoServico)}
                              helperText={String((formik.touched.relacionamentoServico && formik.errors.relacionamentoServico) ?? "")}
                            />
                          )}
                          renderGroup={(params) => (
                            <li key={params.key}>
                              <GroupHeader>{params.group}</GroupHeader>
                              <GroupItems>{params.children}</GroupItems>
                            </li>
                          )}
                        />

                        <FormGroup>
                          <FormControlLabel
                            control={(
                              <Switch
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                name="contratacaoObrigatoria"
                                value={formik.values.contratacaoObrigatoria}
                              />
                            )}
                            label="Contratação obrigatória"
                            labelPlacement="start"
                          />
                          {!!(formik.touched.contratacaoObrigatoria && formik.errors.contratacaoObrigatoria) &&
                            <FormHelperText error>
                              {String((formik.touched.contratacaoObrigatoria && formik.errors.contratacaoObrigatoria) ?? "")}
                            </FormHelperText>
                          }
                        </FormGroup>
                      </Stack>
                    </Grid>
                  </Grow>

                  <Grow
                    in
                    easing="ease-out"
                    mountOnEnter
                    unmountOnExit
                    timeout={800}
                  >
                    <Grid
                      md={6}
                      width="100%"
                    >
                      <Stack
                        direction={{md: 'row', sm: 'column'}}
                        spacing={3}
                        sx={{
                          mb: 3
                        }}
                      >
                        <TextField
                          error={!!(formik.touched.dataInicioAula && formik.errors.dataInicioAula)}
                          fullWidth
                          helperText={String((formik.touched.dataInicioAula && formik.errors.dataInicioAula) ?? "")}
                          label="Data de início das aulas"
                          name="dataInicioAula"
                          type="date"
                          size="small"
                          onBlur={formik.handleBlur}
                          onChange={formik.handleChange}
                          value={formik.values.dataInicioAula}
                          disabled={disabledFields}
                          variant={readOnlyFields ? "standard" : undefined}
                          InputLabelProps={{shrink: true}}
                          inputProps={
                            {readOnly: readOnlyFields,}
                          }
                        />
                      </Stack>

                      <Stack
                        direction={{md: 'row', sm: 'column'}}
                        spacing={3}
                        sx={{
                          mb: 3
                        }}
                      >
                        <TextField
                          error={!!(formik.touched.dataInicioVigencia && formik.errors.dataInicioVigencia)}
                          fullWidth
                          helperText={String((formik.touched.dataInicioVigencia && formik.errors.dataInicioVigencia) ?? "")}
                          label="Início vigência"
                          name="dataInicioVigencia"
                          type="date"
                          size="small"
                          onBlur={formik.handleBlur}
                          onChange={formik.handleChange}
                          value={formik.values.dataInicioVigencia}
                          InputLabelProps={{shrink: true}}
                          disabled={disabledFields}
                          variant={readOnlyFields ? "standard" : undefined}
                          inputProps={
                            {readOnly: readOnlyFields,}
                          }
                        />

                        <TextField
                          error={!!(formik.touched.dataTerminoVigencia && formik.errors.dataTerminoVigencia)}
                          fullWidth
                          helperText={String((formik.touched.dataTerminoVigencia && formik.errors.dataTerminoVigencia) ?? "")}
                          label="Término vigência"
                          name="dataTerminoVigencia"
                          type="date"
                          size="small"
                          onBlur={formik.handleBlur}
                          onChange={formik.handleChange}
                          value={formik.values.dataTerminoVigencia}
                          InputLabelProps={{shrink: true}}
                          disabled={disabledFields}
                          variant={readOnlyFields ? "standard" : undefined}
                          inputProps={
                            {readOnly: readOnlyFields,}
                          }
                        />
                      </Stack>

                      <Stack
                        direction={{md: 'row', sm: 'column'}}
                        spacing={3}
                        sx={{
                          mb: 3
                        }}
                      >
                        <FormGroup>
                          <FormControlLabel
                            control={(
                              <Switch
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                name="considerarProporcionalidade"
                                value={formik.values.considerarProporcionalidade}
                              />
                            )}
                            label="Considerar proporcionalidade"
                            labelPlacement="start"
                          />
                          {!!(formik.touched.considerarProporcionalidade && formik.errors.considerarProporcionalidade) &&
                            <FormHelperText error>
                              {String((formik.touched.considerarProporcionalidade && formik.errors.considerarProporcionalidade) ?? "")}
                            </FormHelperText>
                          }
                        </FormGroup>

                        <TextField
                          error={!!(formik.touched.dataCalculoProporcionalidade && formik.errors.dataCalculoProporcionalidade)}
                          fullWidth
                          helperText={String((formik.touched.dataCalculoProporcionalidade && formik.errors.dataCalculoProporcionalidade) ?? "")}
                          label="Data para cálculo da proporcionalidade"
                          name="dataCalculoProporcionalidade"
                          type="date"
                          size="small"
                          onBlur={formik.handleBlur}
                          onChange={formik.handleChange}
                          InputLabelProps={{shrink: true}}
                          value={formik.values.dataCalculoProporcionalidade}
                          disabled={disabledFields}
                          variant={readOnlyFields ? "standard" : undefined}
                          inputProps={
                            {readOnly: readOnlyFields,}
                          }
                        />
                      </Stack>

                      <Stack
                        direction={{md: 'row', sm: 'column'}}
                        spacing={3}
                        sx={{
                          mb: 3
                        }}
                      >
                        <FormGroup>
                          <FormControlLabel
                            control={(
                              <Switch
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                name="permiteIsencaoMulta"
                                value={formik.values.permiteIsencaoMulta}
                              />
                            )}
                            label="Permite isenção da multa"
                            labelPlacement="start"
                          />
                          {!!(formik.touched.permiteIsencaoMulta && formik.errors.permiteIsencaoMulta) &&
                            <FormHelperText error>
                              {String((formik.touched.permiteIsencaoMulta && formik.errors.permiteIsencaoMulta) ?? "")}
                            </FormHelperText>
                          }
                        </FormGroup>
                      </Stack>

                      <Stack
                        direction={{md: 'row', sm: 'column'}}
                        spacing={3}
                        sx={{
                          mb: 3
                        }}
                      >
                        <Box
                          sx={{
                            width: "100%"
                          }}
                        >
                          <Typography id="qtd-parcelas" gutterBottom>
                            Quantidade de parcelas:
                          </Typography>
                          <Grid container spacing={2} alignItems="center">
                            <Grid
                              xs
                              sx={{
                                pl: 3,
                                pr: 3,
                              }}
                            >
                              <Slider
                                value={formik.values.maxParcelas}
                                onChange={formik.handleChange}
                                aria-labelledby="qtd-parcelas"
                                name="maxParcelas"
                                min={1}
                                max={12}
                                marks={Array.from(Array(12), (_, x) => x + 1).map((parcela) => (
                                  {
                                    value: parcela,
                                    label: parcela+'x',
                                  }
                                ))}
                              />
                            </Grid>
                            <Grid>
                              <Input
                                error={!!(formik.touched.maxParcelas && formik.errors.maxParcelas)}
                                value={formik.values.maxParcelas}
                                size="small"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                name="maxParcelas"
                                endAdornment={<InputAdornment position="end">X</InputAdornment>}
                                inputProps={{
                                  step: 1,
                                  min: 1,
                                  max: 12,
                                  type: 'number',
                                  'aria-labelledby': 'qtd-parcelas',
                                }}
                              />
                            </Grid>
                          </Grid>
                        </Box>
                      </Stack>

                      <Stack
                        direction={{md: 'row', sm: 'column'}}
                        spacing={3}
                        sx={{
                          mb: 3
                        }}
                      >
                        <Box
                          sx={{
                            width: "100%"
                          }}
                        >
                          <Typography id="desconto-a-vista" gutterBottom>
                            Desconto à vista:
                          </Typography>
                          <Grid container spacing={2} alignItems="center">
                            <Grid
                              xs
                              sx={{
                                pl: 3,
                                pr: 3,
                              }}
                            >
                              <Slider
                                value={formik.values.descontoAvista}
                                onChange={formik.handleChange}
                                aria-labelledby="desconto-a-vista"
                                name="descontoAvista"
                                min={0}
                                max={100}
                                marks={[
                                  {
                                    value: 0,
                                    label: '0%',
                                  },
                                  {
                                    value: 50,
                                    label: '50%',
                                  },
                                  {
                                    value: 100,
                                    label: '100%',
                                  },
                                ]}
                              />
                            </Grid>
                            <Grid>
                              <Input
                                error={!!(formik.touched.descontoAvista && formik.errors.descontoAvista)}
                                value={formik.values.descontoAvista}
                                size="small"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                name="descontoAvista"
                                endAdornment={<InputAdornment position="end">%</InputAdornment>}
                                inputProps={{
                                  step: 0.10,
                                  min: 0,
                                  max: 100,
                                  type: 'number',
                                  'aria-labelledby': 'desconto-a-vista',
                                }}
                              />
                            </Grid>
                          </Grid>
                        </Box>
                      </Stack>
                    </Grid>
                  </Grow>
                </Grid>

                <Grid
                  container
                  width="100%"
                  justifyContent="flex-start"
                >
                  <Grid
                    md={3}
                    sx={{
                      mt: 1
                    }}
                    width="100%"
                    justifyContent="flex-start"
                  >
                    <Grow
                      in
                      easing="ease-out"
                    >
                      <Button
                        color="primary"
                        variant="contained"
                        type="submit"
                        startIcon={saving ? <CircularProgress color="primary" size={20}/> : <SaveIcon/>}
                        disabled={(readOnly && !canEditFoto) || saving || !formik.dirty}
                      >
                        Salvar
                      </Button>
                    </Grow>
                  </Grid>
                </Grid>
              </Stack>
            </Stack>
          }

          {tab === tabs.LIST &&
            <Stack spacing={3}>
              <AuthContext.Consumer>
                {({ appContext }) => {
                  const { tables } = appContext || {}
                  return (
                    <CustomTable
                      columns={tables['complementares-em-validacao']?.columns || []}
                      data={(data?.servicosPendentes || []).map(s => {
                        // @ts-ignore
                        s.razaoStatus = "Em Validação"
                        //@ts-ignore
                        s.tipoItemLabel = data?.tiposItemServico?.find(t => t.valor === s.tipoItem.valor)?.descricao
                        return s
                      })}
                      configs={tableConfigs}
                      isLoading={loading}
                      selected={selected}
                      staticAction
                    />
                  )
                }}
              </AuthContext.Consumer>
            </Stack>
          }
        </form>
      </Box>
    </React.Fragment>
  )
}

export default withUnidade(ServicosComplementares, "Serviços Complementares");
