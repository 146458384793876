import {gql} from "@apollo/client";
import deepCopy from "../utils/deepCopy";
import {dispatchGraphError} from "../utils/dispatchGraphError";

export async function SimularXlsx(request) {
    /** @type ApolloClient<NormalizedCacheObject> */
    const client = window.apolloClient;
    return client.mutate({
        mutation:  gql`
            mutation SimularXlsx($request: SimularTrocaCursoRequest) {
                simularXlsx(request: $request)
            }
        `,
        variables: {
            request
        }
    }).then(data => {
        const {
            data: {
                simularXlsx
            },
            errors
        } = data;

        dispatchGraphError(errors);

        return simularXlsx;
    }).catch(console.error)
}