import {ApolloClient, gql, NormalizedCacheObject} from "@apollo/client";
import {dispatchGraphError} from "../utils/dispatchGraphError";
//@ts-ignore
import {Contato, ContatoColaborador, ContatoFilter, ContatoMutation} from "seb-graph-api-types/generated";

export async function All(filter?: ContatoFilter): Promise<{rows: Contato[], count: number}> {
    // @ts-ignore
  const client = window.apolloClient as ApolloClient<NormalizedCacheObject>;
  const {
    data: {
      contatos
    },
    errors
  } = await client.query({
    query: gql`
        query Contatos($filter: ContatoFilter) {
            contatos(filter: $filter) {
                rows {
                    id
                    nome
                    cpf
                    codigoCliente
                }
            }
        }
    `,
    variables: {
      filter
    }
  })

  // @ts-ignore
  dispatchGraphError(errors);

  return contatos;
}

export async function FindOne(id: string): Promise<Contato> {
  // @ts-ignore
  const client = window.apolloClient as ApolloClient<NormalizedCacheObject>;
  const {
    data: {
      contato
    },
    errors
  } = await client.query({
    query: gql`
        query Contato($id: ID) {
            contato(id: $id) {
                id
                nome
                cpf
                rg
                passaporte
                email
                telefoneFixo
                celular
                cep
                bairro
                complemento
                cidade
                estado
                numero
                logradouro
                dataNascimento
                fotoPrincipal {
                    id
                    publicURL
                    props {
                        contentType
                        metadata {
                            raw
                            expiration
                            analysisDecoded {
                                imageQualityReport {
                                    pitch
                                    roll
                                    yaw
                                    blur
                                    overexposure
                                    underexposure
                                    noise
                                    resolution
                                    glasses
                                    eyeOcclusion
                                    mouthOcclusion
                                    foreheadOcclusion
                                    finalScore
                                }
                            }
                        }
                    }
                }
            }
        }
    `,
    variables: {
      id
    }
  })

  // @ts-ignore
  dispatchGraphError(errors);

  return contato;
}

export async function Mutate(data: any): Promise<Contato> {
  // @ts-ignore
  const client = window.apolloClient as ApolloClient<NormalizedCacheObject>;
  const {
    data: {
      contatos
    },
    errors
  } = await client.mutate({
    mutation: gql`
        mutation Contatos($data: ContatoMutation) {
            contatos(data: $data) {
                id
                nome
                cpf
                email
                telefoneFixo
                celular
                dataNascimento
                fotoPrincipal {
                    id
                    publicURL
                    props {
                        contentType
                        metadata {
                            raw
                            expiration
                            analysisDecoded {
                                imageQualityReport {
                                    pitch
                                    roll
                                    yaw
                                    blur
                                    overexposure
                                    underexposure
                                    noise
                                    resolution
                                    glasses
                                    eyeOcclusion
                                    mouthOcclusion
                                    foreheadOcclusion
                                    finalScore
                                }
                            }
                        }
                    }
                }
            }
        }
    `,
    variables: {
      data
    }
  })

  // @ts-ignore
  dispatchGraphError(errors);

  return contatos;
}

export async function GetByContatoMatriculaCRM(idMatricula: string): Promise<Array<Contato>> {
    // @ts-ignore
  const client = window.apolloClient as ApolloClient<NormalizedCacheObject>;
    const {
      data: {
        getByContatoMatriculaCRM
      },
      errors
    } = await client.query({
        query: gql`
            query GetByContatoMatriculaCRM($idMatricula: ID) {
                getByContatoMatriculaCRM(idMatricula: $idMatricula) {
                    id
                    nome
                    email
                    cpf
                    rg
                    dataNascimento
                    celular
                    telefoneFixo
                    fotoPrincipal {
                        id
                        publicURL
                        props {
                            contentType
                            metadata {
                                raw
                                expiration
                                analysisDecoded {
                                    imageQualityReport {
                                        pitch
                                        roll
                                        yaw
                                        blur
                                        overexposure
                                        underexposure
                                        noise
                                        resolution
                                        glasses
                                        eyeOcclusion
                                        mouthOcclusion
                                        foreheadOcclusion
                                        finalScore
                                    }
                                }
                            }
                        }
                    }
                }
            }
        `,
      variables: {
        idMatricula
      }
    })

    // @ts-ignore
  dispatchGraphError(errors);

  return getByContatoMatriculaCRM;
}

// TODO: Implementar
export async function GetColaboratorsByUnit(idUnidade: string): Promise<Array<ContatoColaborador>> {
  // @ts-ignore
  const client = window.apolloClient as ApolloClient<NormalizedCacheObject>;
    const {
      data: {
        getColaboratorsByUnit
      },
      errors
    } = await client.query({
        query: gql`
            query GetColaboratorsByUnit($idUnidade: ID) {
                getColaboratorsByUnit(idUnidade: $idUnidade) {
                    id
                    nome
                    email
                    cpf
                    rg
                    dataNascimento
                    celular
                    telefoneFixo
                    urlImagemPerfil
                }
            }
        `,
      variables: {
        idUnidade
      }
    })

    // @ts-ignore
  dispatchGraphError(errors);

  return getColaboratorsByUnit;
}

// TODO: Implementar o retorno correto
export async function GetVisitasPorUnidade(idUnidade: string) : Promise<Array<any>> {
  // @ts-ignore
const client = window.apolloClient as ApolloClient<NormalizedCacheObject>;
  const {
    data: {
      getVisitasPorUnidade
    },
    errors
  } = await client.query({
      query: gql`
          query GetVisitasPorUnidade($idUnidade: String) {
            getVisitasPorUnidade(idUnidade: $idUnidade) {
              seb_visitaid
              seb_name
              seb_motivo
              seb_unidade
              seb_unidade_name
              seb_data_inicio
              seb_data_fim
              contatos {
                seb_contact_seb_visitaid
                seb_visitaid
                contactid
                contactname
                contactcpf
              }
            }
          }
      `,
    variables: {
      idUnidade
    }
  });

  // @ts-ignore
  dispatchGraphError(errors);

  return getVisitasPorUnidade;
}


export async function GetVisitasPorCRMIdGuardian(visitCrmId: string) : Promise<Array<any>> {
  // @ts-ignore
const client = window.apolloClient as ApolloClient<NormalizedCacheObject>;
  const {
    data: {
      getVisitasPorCRMIdGuardian : {
        data: getByCrmId
      }
    },
    errors
  } = await client.query({
      query: gql`
          query GetVisitasPorCRMIdGuardian($visitCrmId: String) {
            getVisitasPorCRMIdGuardian(visitCrmId: $visitCrmId) {
              succeeded
              errors
              data {
                id
                visitCrmId
                rules {
                  id
                  uId
                  name
                  unitCrmId
                  serviceCrmID
                  intervals {
                    id
                    startTime
                    endTime
                    isSunday
                    isMonday
                    isTuesday
                    isWednesday
                    isThursday
                    isFriday
                    isSaturday
                  }
                }
              }
            }
          }
      `,
    variables: {
      visitCrmId
    }
  });

  // @ts-ignore
  dispatchGraphError(errors);

  return getByCrmId;
}

export async function createContato(data: ContatoMutation) : Promise<Contato> {
  // @ts-ignore
  const client = window.apolloClient as ApolloClient<NormalizedCacheObject>;
    const {
      data: {
        createContato
      },
      errors
  } = await client.mutate({
      mutation: gql`
          mutation CreateContato($data: ContatoMutation) {
            createContato(data: $data) {
              id
              nome
              cpf
              email
              telefoneFixo
              celular
              dataNascimento
              fotoPrincipal {
                id
                publicURL
                props {
                  contentType
                  metadata {
                    raw
                    expiration
                    analysisDecoded {
                      imageQualityReport {
                        pitch
                        roll
                        yaw
                        blur
                        overexposure
                        underexposure
                        noise
                        resolution
                        glasses
                        eyeOcclusion
                        mouthOcclusion
                        foreheadOcclusion
                        finalScore
                      }
                    }
                  }
                }
              }
            }
          }
      `,
    variables: {
      data
    }
  });

  // @ts-ignore
  dispatchGraphError(errors);

  return createContato;
}