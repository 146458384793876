import React, { Fragment, useEffect, useMemo, useState, MouseEvent, ChangeEvent } from "react";
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import * as Yup from 'yup';
import {
  Button,
  Grid,
  TextField,
  Divider,
  Grow,
  CircularProgress,
  StepLabel,
  Step,
  Stepper,
  MenuItem,
  FormGroup,
  FormControlLabel,
  Checkbox
} from "@mui/material";
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import SaveIcon from '@mui/icons-material/Save';
import { pt } from 'yup-locale-pt';
import withUnidade from "../../hocs/withUnidade";
import csatList from "../../utils/csatList";
import * as _contatoService from "../../service/contatos-service";
import * as _aiService from "../../service/ai-service";
import { Unidade } from "../../components/UnidadeAutocompleteSelect/UnidadeAutocompleteSelect";
import CustomTable from "src/components/CustomTable";
import { AuthContext } from "src/context/AuthContext";
import { Add, Edit, KeyboardBackspace, Search } from "@mui/icons-material";
import { useFormik } from 'formik';
import { useFormSteps } from "../../hooks/use-form-step";
import { styled, useTheme } from '@mui/material/styles';
import useMediaQuery from "@mui/material/useMediaQuery";
import { Contato, ImageQualityReport } from "seb-graph-api-types/generated";
import FacialFoto from "src/components/ModalUploadFacialImage/FacialFoto";
import { ModalUploadFacialImage } from "src/components/ModalUploadFacialImage/ModalUploadFacialImage";
import { useCan } from "src/hooks/use-can";
import { useDialog } from "src/hooks/use-dialog";
import ErrorMessage from "src/components/ErrorMessage/ErrorMessage";
import HorarioComponent from "src/components/HorarioComponent";
import { Intervals } from "src/types/guardian-types";
import { diasSemana } from "src/components/HorarioComponent/horario-inputs";
import { useDebounce } from "src/hooks/use-debounce";


Yup.setLocale(pt);

interface FormData {
  id?: string
  nome?: string
  email?: string
  cpf?: string
  telefoneFixo?: string
  celular?: string
  dataNascimento?: string
  fotoPrincipal64?: null | string
  fotoPrincipal?: any
  seb_motivo?: string
  seb_data_inicio?: string
  seb_data_fim?: string
}

interface Props {
  Unidade: Unidade
  handleShowCsat: (key: string) => void
}

const formatarHorario = (rules) => {
  const diasSemana = {
    isMonday: 'Seg',
    isTuesday: 'Ter',
    isWednesday: 'Qua',
    isThursday: 'Qui',
    isFriday: 'Sex',
    isSaturday: 'Sáb',
    isSunday: 'Dom'
  };

  const obterDiasAtivos = (rule) => {
    const diasAtivos = [];
    for (const [key, dia] of Object.entries(diasSemana)) {
      if (rule[key]) {
        diasAtivos.push(dia);
      }
    }
    return diasAtivos;
  }

  const agruparDias = (dias) => {
    const grupos = [];
    let grupoAtual = [dias[0]];

    for (let i = 1; i < dias.length; i++) {
      if (diasSemana[dias[i - 1]] === diasSemana[dias[i] - 1]) {
        grupoAtual.push(dias[i]);
      } else {
        grupos.push(grupoAtual);
        grupoAtual = [dias[i]];
      }
    }

    grupos.push(grupoAtual);
    return grupos;
  }

  return rules.map(rule => {
    const diasAtivos = obterDiasAtivos(rule);
    const grupos = agruparDias(diasAtivos);
    const diasTexto = grupos.map(grupo => {
      if (grupo.length === 1) {
        return grupo[0];
      } else {
        return grupo.join(', ');
      }
    }).join(', ');
    return `${diasTexto} das ${rule.startTime.substring(0, 5)} às ${rule.endTime.substring(0, 5)}`;
  }).join('; ');
}

const QontoConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 10,
    left: 'calc(-50% + 16px)',
    right: 'calc(50% + 16px)',
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: theme.palette.primary.main,
    },
  },
}));

// export type ContatoMutation = {
//   bairro?: InputMaybe<Scalars['String']>;
//   celular?: InputMaybe<Scalars['String']>;
//   cep?: InputMaybe<Scalars['CEP']>;
//   cidade?: InputMaybe<Scalars['City']>;
//   complemento?: InputMaybe<Scalars['String']>;
//   cpf?: InputMaybe<Scalars['CPF']>;
//   dataNascimento?: InputMaybe<Scalars['String']>;
//   email?: InputMaybe<Scalars['Email']>;
//   estado?: InputMaybe<Scalars['String']>;
//   fotoPrincipal?: InputMaybe<Scalars['String']>;
//   fotoSecundaria?: InputMaybe<Scalars['String']>;
//   grauParentescoId?: InputMaybe<Scalars['String']>;
//   id?: InputMaybe<Scalars['ID']>;
//   logradouro?: InputMaybe<Scalars['String']>;
//   nome?: InputMaybe<Scalars['Name']>;
//   numero?: InputMaybe<Scalars['String']>;
//   passaporte?: InputMaybe<Scalars['String']>;
//   rg?: InputMaybe<Scalars['String']>;
//   telefoneFixo?: InputMaybe<Scalars['String']>;
//   unidadeId?: InputMaybe<Scalars['ID']>;
// };

const initialContatoState = {
  id: '',
  nome: '',
  cpf: '',
  celular: '',
  fotoPrincipal: null,
  fotoSecundaria: null,
}


const validationSchema = Yup.object<FormData>({
  nome: Yup.string().nullable(),
  email: Yup.string().email().nullable(),
  rg: Yup.string().nullable(),
  cpf: Yup.string().nullable(),
  telefoneFixo: Yup.string().nullable(),
  celular: Yup.string().nullable(),
  dataNascimento: Yup.string().nullable(),
  fotoPrincipal64: Yup.string().nullable(),
});

const initialValues: FormData = {
  id: null,
  nome: '',
  email: '',
  cpf: '',
  telefoneFixo: '',
  celular: '',
  dataNascimento: '',
  fotoPrincipal64: null,
  fotoPrincipal: null,
  seb_motivo: null,
  seb_data_inicio: null,
  seb_data_fim: null
};

interface ContatoVisita {
  contactid: string;
  contactname: string;
  contactcpf: string;
}
interface Visitas {
  seb_visitaid: string;
  seb_name: string;
  seb_motivo: string | null;
  seb_unidade: string;
  seb_unidadename: string;
  seb_data_inicio: string;
  seb_data_fim: string;
  contatos: ContatoVisita[];
}

const ReconhecimentoFacial = (props: Props) => {
  const {
    Unidade,
    handleShowCsat
  } = props;

  const [defaultVisitas, setDefaultVisitas] = useState([]);
  const [defaultVisitasTratadas, setDefaultVisitasTratadas] = useState([]);
  const [visitas, setVisitas] = useState([]);
  const dialog = useDialog();
  const [loadingVisitas, setLoadingVisitas] = useState(false);
  const [saving, setSaving] = useState(false);
  const [isEsporadico, setIsEsporadico] = useState(false);
  const [loadingHorarios, setLoadingHorarios] = useState(true);
  const [iaGenerativeText, setIaGenerativeText] = useState('');
  useDebounce(() => generateAiHorario(), 1000, iaGenerativeText);
  const steps = useMemo(() => ['Lista das Visitas', 'Gerenciamento da Visita'], []);
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
  const [nomeContato, setNomeContato] = useState<string>('');
  const [cpfContato, setCpfContato] = useState<string>('');
  const [loadingContato, setIsLoadingContato] = useState(false);
  const [selectedContato, setSelectedContato] = useState<Contato>(initialContatoState);
  const [selectedVisita, setSelectedVisita] = useState<Visitas>(null);
  const [entryTime, setEntryTime] = useState<Intervals[]>([]);
  const [exitTime, setExitTime] = useState<Intervals[]>([]);
  const { userCan } = useCan();
  const canCreate = false ?? userCan("cadastro.reconhecimentoFacial.create");
  const canEdit = false ?? userCan("cadastro.reconhecimentoFacial.edit");
  const canEditFoto = true ?? userCan("cadastro.reconhecimentoFacial.edit.foto");
  // const canCreateVisita = false ?? userCan("cadastro.reconhecimentoFacial.create.visita");
  // const canEditVisita = false ?? userCan("cadastro.reconhecimentoFacial.edit.visita");
  // const readOnlyVisitaFields = !(canEditVisita && selectedVisita?.seb_visitaid !== null && !canCreateVisita) && !(canCreateVisita && selectedVisita?.seb_visitaid === null);

  const readOnlyFields = !(canEdit && selectedContato.id !== null && !canCreate) && !(canCreate && selectedContato.id === null);
  const readOnlyPhoto = !(canCreate && selectedContato.id === null) && !(canEditFoto && selectedContato.id !== null);
  const readOnly = readOnlyFields && readOnlyPhoto;
  const hasIdInContato = 'id' in selectedContato && selectedContato?.id && selectedContato?.id?.length;
  const disabledFields = (readOnly && !('id' in selectedContato)) || !selectedContato || (!hasIdInContato && !canCreate);
  const reports = useMemo<ImageQualityReport>(
    () => selectedContato?.fotoPrincipal?.props?.metadata?.analysisDecoded?.imageQualityReport ?? {} as ImageQualityReport,
    [selectedContato]
  );
  const [refreshAuthContext, setRefreshAuthContext] = useState('initial');
  const [showWebcam, setShowWebcam] = useState(false);

  const possiveisMotivos = [
    "Lead - Conhecer a Escola",
    "Funcionário Terceiro",
    "Terceiro - Manutenção",
    "Funcionário de outra Unidade",
    "Buscar Aluno - van, avô etc",
    "Alunos que fazem atividades extras",
    "Outro Motivo"
  ]

  // useEffect(() => {
  //   const url = new URL(window.location.href)
  //   const openUploadModal = url.searchParams.get("openUploadModal")
  //   const contatoId = url.searchParams.get("contatoId")

  //   if (openUploadModal && dialog && selectedContato && selectedContato?.id === contatoId && !showWebcam) {
  //     setShowWebcam(true);
  //     dialog.handleOpen();
  //   }
  // }, [dialog, showWebcam, selectedContato]);

  function formatDate(isoDateString) {
    const date = new Date(isoDateString);

    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();

    return `${day}/${month}/${year}`;
  }

  const isIntervalActive = (startTime: string, endTime: string) => {
    const now = new Date();
    const start = new Date(`${now.toDateString()} ${startTime}`);
    const end = new Date(`${now.toDateString()} ${endTime}`);
    if (start > now) {
      return 'A iniciar';
    } else if (end < now) {
      return 'Inativo';
    } else {
      return 'Ativo';
    }
  }

  const removerPeriodo = (e: MouseEvent, id: number) => {
    e.preventDefault();
    let newEntryIntervals = entryTime.filter((interval) => interval.id !== id);
    let newExitIntervals = exitTime.filter((interval) => interval.id !== id);

    setEntryTime(newEntryIntervals);
    setExitTime(newExitIntervals);
  }

  const adicionarPeriodo = (event: MouseEvent, isEntry: boolean, selectedDay: string) => {
    event.preventDefault();
    const newObj = isEntry ? entryTime : exitTime;

    let newInterval = {
      id: newObj.length + 1,
      startTime: '00:00:00',
      endTime: '00:00:00',
      isSunday: false,
      isMonday: false,
      isTuesday: false,
      isWednesday: false,
      isThursday: false,
      isFriday: false,
      isSaturday: false,
    }
    Object.keys(newInterval).forEach(function (key, index) { if (key === selectedDay) newInterval[key] = true })

    const newIntervals = [...newObj, newInterval]
    if (isEntry) {
      setEntryTime(newIntervals);
    }
    else {
      setExitTime([...newIntervals]);
    }
  }

  const handleChangeIntervalByDrag = (intervals: Intervals[], isEntry: boolean) => {
    if (isEntry)
      setEntryTime(intervals);
    else
      setExitTime(intervals);
  }

  const handleChangeInterval = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, id: number, isEntry: boolean, selectedDay: string) => {
    event.preventDefault();
    let newIntervals: Intervals[] = []
    if (isEntry)
      newIntervals = entryTime;
    else
      newIntervals = exitTime;

    const index = newIntervals.findIndex((interval) => interval.id === id);
    const name = event.target.name;
    let createAnother = false;

    newIntervals.filter((interval) => interval.id !== id).forEach((interval) => {
      diasSemana.forEach((dia) => {
        if (interval[dia.value] && dia.value !== name) {
          createAnother = true;
        }
      })
    });
    if (createAnother) {
      newIntervals[index][name] = event.target.value;
    } else {
      newIntervals[index][name] = event.target.value;
    }
    if (isEntry) {
      setEntryTime(newIntervals);
    } else
      setExitTime(newIntervals);
  };



  const handleMultipleChanges = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, value: boolean, isAll: boolean, isEntry: boolean, selectedDay: string) => {
    event.preventDefault();
    let newIntervals = isEntry ? [...entryTime] : [...exitTime];

    const name = event.target.name;
    if (isAll) {
      diasSemana.forEach((dia) => {
        newIntervals.forEach((interval) => {
          if (interval[dia.value])
            interval[dia.value] = false;
          if (!interval[selectedDay])
            interval[name] = value;
        });
      });
    } else {
      newIntervals.forEach((interval) => {
        if (interval[name]) {
          interval[name] = false;
        }
        if (interval[selectedDay]) {
          interval[name] = value;
        }
      });
    }
    if (isEntry) {
      setEntryTime(newIntervals);
    } else
      setExitTime(newIntervals);
  }

  useEffect(() => {
    const getVisitas = async () => {
      setLoadingVisitas(true);
      try {
        const visitas = await _contatoService.GetVisitasPorUnidade(Unidade.id);
        const rulesByVisit = await Promise.all(visitas.map(async visita => {
          return await _contatoService.GetVisitasPorCRMIdGuardian(visita.seb_visitaid);
        }));
        setDefaultVisitas(visitas);
        let visitantes = [];
        visitas.map((visita) => {
          // @ts-ignore
          visita.rules = rulesByVisit.find(groupedRules => groupedRules?.visitCrmId === visita.seb_visitaid || [])[0]?.rules[0] ?? {};
          visita.contatos.map((contato) => {
            visitantes.push({
              seb_visitaid: visita.seb_visitaid,
              seb_name: visita.seb_name,
              seb_motivo: visita.seb_motivo,
              seb_unidade: visita.seb_unidade,
              seb_unidadename: visita.seb_unidadename,
              seb_data_inicio: formatDate(visita.seb_data_inicio),
              seb_data_fim: formatDate(visita.seb_data_fim),
              contactid: contato.contactid,
              contactname: contato.contactname,
              contactcpf: contato.contactcpf,
              seb_horario: formatarHorario(visita.rules.intervals),
              intervalStatus: isIntervalActive(visita.rules.startTime, visita.rules.endTime)
            });
            return contato;
          });
          return visita;
        });
        setDefaultVisitasTratadas(visitantes);
        setVisitas(visitantes);
      } catch (error) {
        console.error(error);
      } finally {
        setLoadingVisitas(false);
      }
    }
    getVisitas();
  }, [Unidade.id]);

  const handleSelectContato = async (contato) => {
    setIsLoadingContato(true);
    changeStep(currentStep + 1);
    setSelectedContato(initialContatoState);
    setSelectedVisita(null);
    try {
      const selectedVisita = defaultVisitas.find(visita => visita.seb_visitaid === contato.seb_visitaid);
      setSelectedVisita(selectedVisita);
      const visitante = await _contatoService.FindOne(contato.contactid);
      setSelectedContato(visitante);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoadingContato(false);
    }
  };

  const handleNovaVisita = () => {
    setSelectedContato(initialContatoState);
    setSelectedVisita(null);
    changeStep(0);
  };

  const generateAiHorario = async () => {
    setLoadingHorarios(true);
    try {
      const request = {
        message: iaGenerativeText,
        json_response: true
      };
      const { entry, exit } = await _aiService.generateAiInterval(request);
      console.log('entry: ', entry);
      setEntryTime(entry);
      setExitTime(exit);
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingHorarios(false);
    }
  }

  const onChangeCpf = (e) => {
    const { value } = e.target;
    if (value.length > 14) return;
    let cpf = value.replace(/\D/g, '');
    cpf = cpf.replace(/(\d{3})(\d)/, '$1.$2');
    cpf = cpf.replace(/(\d{3})(\d)/, '$1.$2');
    cpf = cpf.replace(/(\d{3})(\d{1,2})$/, '$1-$2');
    setCpfContato(cpf);
  }

  const formik = useFormik({
    initialValues: { ...initialValues, ...selectedContato, ...selectedVisita },
    validationSchema,
    enableReinitialize: true,
    onSubmit: async (values, helpers): Promise<void> => {
      setSaving(true);
      try {
        const request = {
          message: iaGenerativeText,
          json_response: true
        }
        const resp = await _aiService.generateAiInterval(request);
        console.log('resp', resp);
      } catch (error) {
        console.error(error);
      } finally {
        setSaving(false);
      }
    }
  });

  const rootInputStyles = {
    '&:focus-within fieldset, &:focus-visible fieldset': {
      boxShadow: 'none !important',
    },
  };

  const filtrarUsuarios = async () => {
    if (!nomeContato && !cpfContato) {
      setVisitas(defaultVisitasTratadas);
      return;
    } else if (nomeContato || cpfContato) {
      const filteredVisitas = defaultVisitasTratadas.filter(visita => {
        if (nomeContato && cpfContato) {
          return visita.contactname.toLowerCase().includes(nomeContato.toLowerCase()) && visita.contactcpf.includes(cpfContato);
        } else if (nomeContato) {
          return visita.contactname.toLowerCase().includes(nomeContato.toLowerCase());
        } else if (cpfContato) {
          return visita.contactcpf.includes(cpfContato);
        }
      });
      setVisitas(filteredVisitas);
    }
  }


  const handleEnterKeyPress = (event) => {
    if (event.key === 'Enter') {
      filtrarUsuarios();
    }
  };

  const visitanteComponents = [
    <>
      <Grid
        container
        spacing={2}
        sx={{
          mt: 2,
          mb: 2
        }}
      >
        <Grid
          item
          xs={12}
          md={4}
        >
          <TextField
            fullWidth
            label="Nome"
            variant="outlined"
            size="medium"
            value={nomeContato}
            onChange={(e) => setNomeContato(e.target.value)}
            onKeyUp={handleEnterKeyPress}
            InputProps={{
              sx: {
                ...rootInputStyles,
              },
            }}
          />
        </Grid>
        <Grid
          item
          xs={12}
          md={4}
        >
          <TextField
            fullWidth
            label="CPF"
            variant="outlined"
            size="medium"
            value={cpfContato}
            onChange={(e) => onChangeCpf(e)}
            onKeyUp={handleEnterKeyPress}
            InputProps={{
              sx: {
                ...rootInputStyles,
              },
            }}
          />
        </Grid>
        <Grid
          item
          xs={12}
          md={4}
        >
          <Button
            variant="contained"
            color="primary"
            size="large"
            startIcon={<Search />}
            disabled={loadingVisitas || !visitas}
            onClick={filtrarUsuarios}
          >
            Buscar
          </Button>
        </Grid>
      </Grid>
      {defaultVisitasTratadas.length > 0 && visitas.length === 0 ? (
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          alignContent="center"
          sx={{
            minHeight: '400px'
          }}
        >
          <Grid
            item
            xs={12}
          >
            <ErrorMessage
              title="Nenhum resultado encontrado"
              message="Não encontramos nenhuma visita com esses dados"
              info
            />
          </Grid>
          <Grid
            item
            xs={12}
            display="flex"
            justifyContent="center"
          >
            <Stack
              flexDirection="row"
              spacing={2}
            >
              <Button
                variant="contained"
                color="primary"
                size="large"
                startIcon={<Search />}
                onClick={() => setVisitas(defaultVisitasTratadas)}
              >
                Mostrar todos
              </Button>
              <Button
                variant="contained"
                color="primary"
                size="large"
                startIcon={<Add />}
                onClick={() => setVisitas(defaultVisitasTratadas)}
                sx={{
                  mt: '0 !important',
                  ml: '16px !important'
                }}
              >
                Nova Visita
              </Button>
            </Stack>
          </Grid>
        </Grid>
      ) : (
        defaultVisitasTratadas.length === 0 && visitas.length === 0 && !loadingVisitas ? (
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            alignContent="center"
            sx={{
              minHeight: '400px'
            }}
          >
            <Grid
              item
              xs={12}
            >
              <ErrorMessage
                title="Nenhuma visita para essa Unidade"
                message="Não encontramos nenhuma visita"
                info
              />
            </Grid>
            <Grid
              item
              xs={12}
              display="flex"
              justifyContent="center"
            >
              <Stack
                flexDirection="row"
                spacing={2}
              >
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  startIcon={<Add />}
                  onClick={() => setVisitas(defaultVisitasTratadas)}
                >
                  Criar Visita
                </Button>
              </Stack>
            </Grid>
          </Grid>
        ) : (
          <AuthContext.Consumer key={refreshAuthContext} >
            {({ appContext }) => {
              const { tables } = appContext || {}
              const visitaColumns =
                tables['listvisitas-table']?.columns || []
              return (
                <CustomTable
                  columns={visitaColumns}
                  data={visitas || []}
                  selected={[]}
                  configs={tableConfigs}
                  isLoading={loadingVisitas}
                  staticAction={true}
                />
              )
            }}
          </AuthContext.Consumer>
        ))}
    </>,
    <Stack
      spacing={4}
      useFlexGap
      flexWrap="wrap"
    >
      <form>
        <Typography variant="h6">
          Gerenciamento
        </Typography>
        <Grid
          container
          spacing={4}
          width="100%"
          justifyContent="center"
          mt={2}
          ml="0 !important"
        >
          <Grid
            container
            md={6}
            width="100%"
            justifyContent="center"
          >
            <Grow
              in
              style={{ transformOrigin: '0 0 0' }}
              {...{ timeout: 1000 }}
            >
              <Grid
                md={6}
              >
                <FacialFoto
                  title="Foto 1"
                  url={formik?.values?.fotoPrincipal64 || formik?.values?.fotoPrincipal?.publicURL}
                  finalScore={!formik.dirty ? reports?.finalScore : undefined}
                  disabled={readOnlyPhoto || !selectedContato}
                  width={200}
                  height={300}
                  loading={!selectedContato || loadingContato}
                  onClick={dialog.handleOpen}
                  placeholder="Anexar Foto"
                  icon={null}
                  blobProps={!formik.dirty ? selectedContato?.fotoPrincipal?.props : undefined}
                />

                <ModalUploadFacialImage
                  title="Foto 1"
                  contatoId={selectedContato?.id}
                  url={formik?.values?.fotoPrincipal64 || formik?.values?.fotoPrincipal?.publicURL}
                  finalScore={reports?.finalScore}
                  blobProps={selectedContato?.fotoPrincipal?.props}
                  loading={!selectedContato || loadingContato}
                  onClose={dialog.handleClose}
                  open={dialog.open}
                  showStats={!formik.dirty}
                  showWebcam={showWebcam}
                  onUpload={(_, base64) => {
                    formik.setFieldValue('fotoPrincipal64', base64)
                  }}
                />
              </Grid>
            </Grow>
          </Grid>

          <Grow
            in={!loadingContato}
            easing="ease-out"
            mountOnEnter
            unmountOnExit
            timeout={800}
          >
            <Grid
              md={6}
              width="100%"
            >
              <Stack
                direction={{ md: 'row', sm: 'column' }}
                spacing={3}
                sx={{
                  mb: 4
                }}
              >
                <Typography variant="h6">
                  Informações do Contato
                </Typography>
              </Stack>
              <Stack
                direction={{ md: 'row', sm: 'column' }}
                spacing={3}
                sx={{
                  mb: 3
                }}
              >
                <TextField
                  error={!!(formik.touched.nome && formik.errors.nome)}
                  fullWidth
                  helperText={String((formik.touched.nome && formik.errors.nome) ?? "")}
                  label="Nome"
                  name="nome"
                  size="small"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.nome}
                  disabled={disabledFields}
                  inputProps={
                    { readOnly: readOnlyFields, }
                  }
                />
              </Stack>

              <Stack
                direction={{ md: 'row', sm: 'column' }}
                spacing={3}
                sx={{
                  mb: 3
                }}
              >
                <TextField
                  error={!!(formik.touched.email && formik.errors.email)}
                  fullWidth
                  helperText={String((formik.touched.email && formik.errors.email) ?? "")}
                  label="E-mail"
                  name="email"
                  size="small"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.email}
                  disabled={disabledFields}
                  inputProps={
                    { readOnly: readOnlyFields, }
                  }
                />
              </Stack>

              <Stack
                direction={{ md: 'row', sm: 'column' }}
                spacing={3}
                sx={{
                  mb: 3
                }}
              >
                <TextField
                  error={!!(formik.touched.cpf && formik.errors.cpf)}
                  fullWidth
                  helperText={String((formik.touched.cpf && formik.errors.cpf) ?? "")}
                  label="CPF"
                  name="cpf"
                  size="small"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.cpf}
                  disabled={disabledFields}
                  inputProps={
                    { readOnly: readOnlyFields, }
                  }
                />

                <TextField
                  error={!!(formik.touched.celular && formik.errors.celular)}
                  fullWidth
                  helperText={String((formik.touched.celular && formik.errors.celular) ?? "")}
                  label="Telefone Celular"
                  name="celular"
                  size="small"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.celular}
                  disabled={disabledFields}
                  inputProps={
                    { readOnly: readOnlyFields, }
                  }
                />
              </Stack>

              <Stack
                direction={{ md: 'row', sm: 'column' }}
                spacing={3}
                sx={{
                  mb: 4,
                  mt: 1
                }}
              >
                <Typography variant="h6">
                  Informações da Visita
                </Typography>
              </Stack>
              <Stack
                direction={{ md: 'row', sm: 'column' }}
                spacing={3}
                sx={{
                  mb: 3
                }}
              >
                <TextField
                  error={!!(formik.touched.seb_motivo && formik.errors.seb_motivo)}
                  fullWidth
                  helperText={String((formik.touched.seb_motivo && formik.errors.seb_motivo) ?? "")}
                  label="Motivo da visita"
                  name="seb_motivo"
                  size="small"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  select={selectedVisita && selectedVisita.seb_motivo ? false : true}
                  value={formik.values.seb_motivo}
                >
                  {selectedVisita && selectedVisita.seb_motivo ? selectedVisita.seb_motivo : possiveisMotivos.map((motivo) => (
                    <MenuItem key={motivo} value={motivo}>
                      {motivo}
                    </MenuItem>
                  ))}
                </TextField>
              </Stack>
              <Stack
                direction={{ md: 'row', sm: 'column' }}
                spacing={3}
                sx={{
                  mb: 3
                }}
              >
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={isEsporadico}
                        onChange={() => setIsEsporadico(true)}
                        name="isEsporadico"
                        color="primary"
                      />
                    }
                    label="Visita Esporádica"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={!isEsporadico}
                        name="isEsporadico"
                        onChange={() => setIsEsporadico(false)}
                        color="primary"
                      />
                    }
                    label="Visita Recorrente"
                  />
                </FormGroup>
              </Stack>

              <Stack
                direction={{ md: 'row', sm: 'column' }}
                spacing={3}
                sx={{
                  mb: 3
                }}
              >
                <TextField
                  error={!!(formik.touched.seb_data_inicio && formik.errors.seb_data_inicio)}
                  fullWidth
                  helperText={String((formik.touched.seb_data_inicio && formik.errors.seb_data_inicio) ?? "")}
                  label="Data Inícial"
                  name="seb_data_inicio"
                  size="small"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  type="date"
                  InputLabelProps={{ shrink: true }}
                  value={formik.values.seb_data_inicio}
                  disabled={disabledFields}
                />
                <TextField
                  error={!!(formik.touched.seb_data_fim && formik.errors.seb_data_fim)}
                  fullWidth
                  helperText={String((formik.touched.seb_data_fim && formik.errors.seb_data_fim) ?? "")}
                  label="Data Final"
                  name="seb_data_fim"
                  size="small"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  type="date"
                  InputLabelProps={{ shrink: true }}
                  value={formik.values.seb_data_fim}
                  disabled={disabledFields}
                />
              </Stack>
            </Grid>
          </Grow>
          <Grow
            in={!loadingContato}
            easing="ease-out"
            mountOnEnter
            unmountOnExit
            timeout={800}
          >
            <Grid
              xs={12}
            >
              <Stack
                direction={{ md: 'row', sm: 'column' }}
              >
                <Typography variant="h6">
                  Descreva os dias e horários que serão liberados
                </Typography>
              </Stack>
              <Stack
                direction={{ md: 'row', sm: 'column' }}
                spacing={3}
                sx={{
                  mb: 3
                }}
              >
                <TextField
                  fullWidth
                  label="De segunda a sexta das 06:00 as 18:00"
                  name="iaGenerativeText"
                  size="small"
                  multiline
                  rows={4}
                  onChange={(e) => setIaGenerativeText(e.target.value)}
                  value={iaGenerativeText}
                  disabled={disabledFields}
                />
              </Stack>
            </Grid>
          </Grow>
            {iaGenerativeText && iaGenerativeText !== "" && (
              <Grid
                xs={12}
              >
                <HorarioComponent
                  entryTime={entryTime}
                  exitTime={exitTime}
                  loadingHorarios={loadingHorarios}
                  handleChangeInterval={handleChangeInterval}
                  handleMultipleChanges={handleMultipleChanges}
                  adicionarPeriodo={adicionarPeriodo}
                  removerPeriodo={removerPeriodo}
                  updatePeriodo={(() => console.log('update'))}
                  handleChangeIntervalByDrag={handleChangeIntervalByDrag}
                />
              </Grid>
            )}
          <Grid
            container
            width="100%"
            justifyContent="flex-end"
          >
            <Grid
              md={3}
              sx={{
                mt: 1
              }}
              width="100%"
              justifyContent="center"
            >
              <Grow
                in={!loadingContato}
                easing="ease-out"
              >
                <Button
                  color="primary"
                  variant="contained"
                  // type="submit"
                  onClick={generateAiHorario}
                  startIcon={saving ? <CircularProgress color="primary" size={20} /> : <SaveIcon />}
                  disabled={(readOnly && !canEditFoto) || saving || !formik.dirty || (!hasIdInContato && !canCreate)}
                >
                  {(hasIdInContato || (!hasIdInContato && !canCreate)) ? "Salvar" : "Criar"}
                </Button>
              </Grow>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </Stack>
  ];


  const tableConfigs = {
    selection: false,
    editing: false,
    isEndedList: true,
    actions: [
      { id: "select-visit", label: <><Edit />Gerenciar</>, },
      { id: "add-visit", label: <><Add />Adicionar Visitante </>, },
    ],
    onClickAction: (action, row) => {
      switch (action.id) {
        case "select-visit":
          handleSelectContato(row);
          break;
        case "add-visit":
          handleSelectContato(row);
          break;
        default:
          console.warn("Unhandled option: ", action);
          break;
      }
    },
  }
  const { currentStep, currentComponent, changeStep } = useFormSteps(visitanteComponents);
  return (
    <Fragment>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
        }}
      >
        <Stack
          spacing={3}
          sx={{ mb: 3 }}
        >
          <div>
            <Typography
              variant="h4"
              sx={{
                mb: 3
              }}
            >
              Reconhecimento Facial
            </Typography>
          </div>
        </Stack>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Divider />
            <Grow
              in
              style={{ transformOrigin: '0 0 0' }}
              {...{ timeout: 1000 }}
            >
              <Stack spacing={3}>
                <Stack
                  spacing={4}
                  useFlexGap
                  flexWrap="wrap"
                  justifyContent="center"
                  alignItems="center"
                  position="relative"
                  sx={{
                    minHeight: 200,
                    backgroundColor: 'var(--bs-gray-200)',
                    borderRadius: 1,
                  }}
                >
                  <Stack
                    spacing={2}
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                    sx={{
                      mt: isSmall ? 6 : 3,
                    }}
                  >
                    <Typography
                      variant="h6"
                      textAlign="center"
                      sx={{ px: 2 }}
                    >
                      Gerenciamento dos Visitantes na Unidade
                    </Typography>
                    <Typography
                      variant="body1"
                      textAlign="center"
                      sx={{ px: 2 }}
                    >
                      {currentStep === 0 ?
                        'Aqui você pode visualizar as visitas agendadas na unidade.' :
                        'Gerencie o visitante selecionado.'
                      }
                    </Typography>
                  </Stack>
                  {isSmall ? (
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        flexGrow: 1,
                        gap: { xs: 5, md: 'none' },
                      }}
                    >
                      <Stepper
                        id="desktop-stepper"
                        activeStep={currentStep}
                        orientation="vertical"
                        connector={<QontoConnector />}
                        sx={{
                          width: '100%',
                          pb: 2
                        }}
                      >
                        {steps.map((label) => (
                          <Step
                            sx={{
                              ':first-child': { pl: 0 },
                              ':last-child': { pr: 0 },
                            }}
                            key={label}
                          >
                            <StepLabel>{label}</StepLabel>
                          </Step>
                        ))}
                      </Stepper>
                    </Box>
                  ) : (
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        flexGrow: 1,
                        minWidth: '80%',
                        gap: { xs: 5, md: 'none' },
                      }}
                    >
                      <Stepper
                        id="desktop-stepper"
                        activeStep={currentStep}
                        connector={<QontoConnector />}
                        sx={{
                          width: '100%',
                          height: 40,
                        }}
                      >
                        {steps.map((label) => (
                          <Step
                            sx={{
                              ':first-child': { pl: 0 },
                              ':last-child': { pr: 0 },
                            }}
                            key={label}
                          >
                            <StepLabel>{label}</StepLabel>
                          </Step>
                        ))}
                      </Stepper>
                    </Box>
                  )}
                  <Button
                    disabled={currentStep === 0}
                    color="primary"
                    type="button"
                    sx={{
                      position: 'absolute',
                      top: 10,
                      left: 10,
                      width: 'auto'
                    }}
                    onClick={(e) => changeStep(currentStep - 1, e)}
                    startIcon={<KeyboardBackspace />}
                  >
                    {currentStep === 0 ? '' : 'Anterior'}
                  </Button>
                </Stack>
              </Stack>
            </Grow>
            {currentComponent &&
              <Grow
                in
                style={{ transformOrigin: '0 0 0' }}
                {...{ timeout: 1000 }}
              >
                <Box
                  sx={{
                    mt: 3,
                    minHeight: 200
                  }}
                >
                  {currentComponent}
                </Box>
              </Grow>
            }
          </Grid>
        </Grid>
      </Box>
    </Fragment>
  )
}

export default withUnidade(
  ReconhecimentoFacial,
  "Reconhecimento Facial",
  csatList.alteracaoCatracaContato
);