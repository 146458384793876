import { useEffect, useState } from 'react';

export const useDebounce = (callback: Function, delay: number, observedValue: any) => {
    const [timeoutId, setTimeoutId] = useState<number | null>(null);
    useEffect(() => {
        if (timeoutId) {
            clearTimeout(timeoutId);
        }
        const handle = window.setTimeout(() => {
            callback(observedValue);
        }, delay);
        setTimeoutId(handle);
        return () => {
            if (timeoutId) {
                clearTimeout(timeoutId);
            }
        };
    }, [observedValue]);
}
