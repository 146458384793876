import React, {useEffect} from 'react';
import {Autocomplete, Chip, Divider, MenuItem, TextField} from "@mui/material";
import * as _matriculaService from "../../service/matricula-service";
import {useRecoilState, useRecoilValue} from "recoil";
import {selectedMatriculaState} from "../../recoil/atoms/selectedMatriculaState";
import {selectedUnidadeState} from "../../recoil/atoms/selectedUnidadeState";
import {matriculasState} from "../../recoil/atoms/matriculasState";
import {darken, lighten, styled} from '@mui/system';
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import {selectedAnoLetivoState} from "../../recoil/atoms/selectedAnoLetivoState";
import {loadingMatriculasState} from "../../recoil/atoms/loadingMatriculasState";
import useSelectMatricula from "../../hooks/use-select-matricula";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import {ExibeMensagem} from "../shared/Message";

export type Matricula = {
  anoLetivo_Name: string;
  codigoCliente: string;
  codigoMatricula: string;
  codigoMatriculaErp: string;
  estudante_Name: string;
  idAluno: string;
  idMatricula: string;
  periodo: string;
  servico: string;
  status: string;
  turma_Name: string;
  tipoItem: string;
}

const GroupHeader = styled('div')(({theme}) => ({
  position: 'sticky',
  top: '-8px',
  padding: '4px 10px',
  color: theme.palette.primary.main,
  backgroundColor:
    theme.palette.mode === 'light'
      ? lighten(theme.palette.primary.light, 0.85)
      : darken(theme.palette.primary.main, 0.8),
}));

const GroupItems = styled('ul')({
  padding: 0,
});

function MatriculaAutocompleteSelect(
  {
    maxWidth = 600,
  }
) {
  const yearNow = new Date().getFullYear();
  const [year, setYear] = useRecoilState(selectedAnoLetivoState)
  const selectedUnidade = useRecoilValue(selectedUnidadeState);
  const [matriculas, setMatriculas] = useRecoilState(matriculasState);
  const matricula = useRecoilValue(selectedMatriculaState);
  const [loading, setLoading] = useRecoilState(loadingMatriculasState);
  const selected = matricula !== null ? {
    label: `${matricula?.estudante_Name} - CRM: ${matricula?.codigoMatricula} - ERP: ${matricula?.codigoMatriculaErp} - Cliente: ${matricula?.codigoCliente} - Período: ${matricula?.periodo} - Status: ${matricula?.status} - Curso: ${matricula?.servico}`,
    value: matricula
  } : null;
  const options = (matriculas || []).map(u => ({
    label: `${u?.estudante_Name} - CRM: ${u?.codigoMatricula} - ERP: ${u?.codigoMatriculaErp} - Cliente: ${u?.codigoCliente} - Período: ${u?.periodo} - Status: ${u?.status} - Curso: ${u?.servico}`,
    value: u
  }));
  const {selecioneMatricula} = useSelectMatricula();

  useEffect(() => {
    if (selectedUnidade && selectedUnidade.id) {
      setLoading(true);
      setMatriculas([]);
      const request = {
        CodigoMatricula: "",
        CodigoMatriculaErp: "",
        AnoLetivo: year,
        Estudante_Name: "",
        CodigoCliente: "",
        IdUnidade: selectedUnidade.id,
        TipoFiltro: "matriculas"
      };

      _matriculaService.ListMatriculas(request)
        .then(matriculas => {
          const selectedMatricula = matriculas.find(m => m.idMatricula === matricula?.idMatricula)
          if (!selectedMatricula) {
            const newMatricula = matriculas.find(m => m.idAluno === matricula?.idAluno && m?.tipoItem === "Serviço Regular");
            if (newMatricula) {
              selecioneMatricula(newMatricula);
              ExibeMensagem(`Selecionamos automaticamente a matricula no ano letivo ${year}`, "", true);
            } else if (matricula) {
              selecioneMatricula(null);
              ExibeMensagem(`Este aluno não possui matricula no ano letivo ${year}`, "1", true);
            }
          }
          setMatriculas(matriculas);
          setLoading(false);
        });
    }
  }, [selectedUnidade, year]);
  
  useEffect(() => {
    const url = new URL(window.location.href)
    const matriculaId = url.searchParams.get("matriculaId")
    
    if (matriculaId && matriculas && matriculas.length && !matricula) {
      selecioneMatricula((matriculas || []).find(u => u.idMatricula === matriculaId))
    }
  }, [matriculas, matricula]);

  return (
    <React.Fragment>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          width: '100%',
          maxWidth,
          alignItems: 'center',
          m: 2,
          gap: 2,
        }}
      >
        <Autocomplete
          openOnFocus
          disablePortal
          options={options.sort((a, b) => -b.value.estudante_Name.localeCompare(a.value.estudante_Name))}
          isOptionEqualToValue={(option, value) => option.value.idMatricula === value.value.idMatricula}
          groupBy={(option) => option.label.charAt(0).toUpperCase()}
          sx={{
            maxWidth: 600,
            width: '100%',
          }}
          // @ts-ignore
          value={selected}
          // @ts-ignore
          onChange={(event, newValue: { label: string, value: Matricula }) => {
            selecioneMatricula(newValue?.value);
          }}
          size={'small'}
          disabled={!selectedUnidade || !selectedUnidade.id}
          noOptionsText={'Nenhuma matrícula encontrada'}
          loading={loading}
          disableClearable
          loadingText={'Carregando...'}
          renderOption={(props, option) => (
            <Box component="li" sx={{width: "100%"}} {...props}>
              <Stack spacing={1} sx={{width: "100%"}}>
                <Typography
                  color={String(option.value.status).toLowerCase() === "cancelada" ? "error" : "primary"}
                  sx={{
                    textDecoration: String(option.value.status).toLowerCase() === "cancelada" ? "line-through" : ""
                  }}
                  variant="h6"
                >
                  <b>{option.value.estudante_Name}</b>
                </Typography>
                <Stack spacing={1} direction="column">
                  <Typography variant="caption">
                    <b>CRM:</b> {option.value?.codigoMatricula} - <b>ERP:</b> {option.value?.codigoMatriculaErp}
                  </Typography>
                </Stack>
                <Stack spacing={1} direction="column">
                  <Typography variant="caption">
                    <b>Tipo:</b> {option.value?.tipoItem} - <b>Período:</b> {option.value?.periodo}
                  </Typography>
                </Stack>
                <Stack spacing={1} direction="column">
                  <Typography variant="caption">
                    <b>Curso:</b>{" "}
                    <Typography variant="caption">
                      {option.value?.servico}
                    </Typography>
                  </Typography>
                </Stack>
                {option.value?.status && (
                  <Stack spacing={1} direction="column">
                    <Typography variant="caption">
                      <b>Status:</b> {" "}
                      <Chip
                        variant="outlined"
                        size="small"
                        label={String(option.value.status).toLowerCase()}
                        color={String(option.value.status).toLowerCase() === "cancelada" ? "error" : "primary"}
                      />
                    </Typography>
                  </Stack>
                )}
                <Divider/>
              </Stack>
            </Box>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Matrícula"
              autoComplete="off"
              type="text"
              name="unidade"
              InputProps={{
                ...params.InputProps,
                autoComplete: 'off',
                startAdornment: (
                  <React.Fragment>
                    {loading ? <CircularProgress color="primary" size={20}/> : null}
                    {params.InputProps.endAdornment}
                  </React.Fragment>
                ),
              }}
            />
          )}
          renderGroup={(params) => (
            <li key={params.key}>
              <GroupHeader>{params.group}</GroupHeader>
              <GroupItems>{params.children}</GroupItems>
            </li>
          )}
        />

        <TextField
          defaultValue={year}
          fullWidth
          sx={{
            width: 120,
            height: 47,
          }}
          label="Ano Letivo"
          name="anoLetivo"
          select
          disabled={!selectedUnidade || !selectedUnidade.id}
          size="small"
          onChange={(event) => {
            setYear(event.target.value as string);
          }}
        >
          {Array.from({length: (yearNow - 2021) + 2}, (_, i) => 2021 + i).map((year) => (
            <MenuItem key={year} value={String(year)}>
              {year}
            </MenuItem>
          ))}
        </TextField>
      </Box>
    </React.Fragment>
  );
}

export default MatriculaAutocompleteSelect;
