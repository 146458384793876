export const gtmConfig = {
  containerId: process.env.REACT_APP_GTM_CONTAINER_ID
};
export const appConfig = {
  appId: process.env.REACT_APP_CLIENT_ID,
}

export const graphApiConfig = {
  uri: process.env.REACT_APP_API_URL
}

