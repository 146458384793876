import React, { useState } from 'react'
import { ExibeMensagem, ExibeListMensagem } from '../shared/Message'
import { useFormik } from "formik";
import { ReplayParcialRegular } from '../../service/integracaoMatricula-service'
import LoadingAnimation from '../LoadingAnimation/LoadigAnimation'
import * as Yup from 'yup'
import {
  Container,
  Box,
  Typography,
  Grow,
  Stack,
  TextField,
  Grid,
  Paper,
  Checkbox,
  FormControlLabel,
  Button,
  CircularProgress,
  InputAdornment,
  OutlinedInput,
  Slider,
  Input
} from '@mui/material'
import { Percent, Save } from '@mui/icons-material';

interface EnvioItems {
  enviarFichaFinanceira: boolean
  enviarTransacaoFinanceira: boolean
  enviarServicoMatricula: boolean
  enviarOrdemVenda: boolean
}

interface FormValues {
  codigoMatricula: string | null
  qntParcelaRegular: number | null
  qntParcelaMaterial: number | null
  qntParcelaRegularEntrada: number | null
  percentualDescontoEntrada: number | null
  dataReferencia: string | null
  envioItems: EnvioItems
}

const validationSchema = Yup.object({
  codigoMatricula: Yup.string().required('Campo obrigatório'),
  dataReferencia: Yup.string().required('Campo obrigatório'),
  qntParcelaRegular: Yup.number().nullable(),
  qntParcelaMaterial: Yup.number().nullable(),
  qntParcelaRegularEntrada: Yup.number().nullable(),
  percentualDescontoEntrada: Yup.number().nullable(),
  envioItems: Yup.object().nullable()
})
const rootInputStyles = {
  '&:focus-within fieldset, &:focus-visible fieldset': {
    boxShadow: 'none !important'
  }
}

const initialValues: FormValues = {
  codigoMatricula: null,
  qntParcelaRegular: 0,
  qntParcelaMaterial: 0,
  qntParcelaRegularEntrada: 0,
  percentualDescontoEntrada: null,
  dataReferencia: null,
  envioItems: {
    enviarFichaFinanceira: false,
    enviarTransacaoFinanceira: false,
    enviarServicoMatricula: false,
    enviarOrdemVenda: false
  }
}

export const ReplayIntegracaoRegularParcial = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [saving, setSaving] = useState(false);


  const formik = useFormik({
    initialValues,
    validationSchema,
    enableReinitialize: true,
    onSubmit: async (values, helpers): Promise<void> => {
      try {
        console.log(values)
        if (saving) return;

        setSaving(true);

        await ReplayParcialRegular({
          ...values
        }).then(result => {
          setIsLoading(false)
          setSaving(false);
          if (result)
            ExibeListMensagem(result.messages)
          else
            ExibeMensagem('Não foi possível obter retorno da rotina, verifique se código ERP foi criado na matrícula', '2')
        });
      } catch (error) {
        console.error(error);
        setSaving(false);
        ExibeMensagem('Erro ao salvar', '2');
      }
    }
  });

  const setMaxNumber = (value: number): number => {
    return value > 16 ? 16 : value;
  }

  if (isLoading) {
    return <LoadingAnimation> </LoadingAnimation>
  }

  return (
    <Container>
      <Box
        component='main'
        sx={{
          flexGrow: 1
        }}
      >
        <Stack sx={{ mb: 3 }}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between'
            }}
          >
            <Typography variant='h4'>
              Replay Integração Parcial de Serviço Regular
            </Typography>
          </Box>
        </Stack>
        <Grow
          in={!isLoading}
          easing='ease-out'
          mountOnEnter
          unmountOnExit
          timeout={800}
        >
          <Paper
            sx={{
              p: 2,
              display: 'flex',
              flexDirection: 'column',
              gap: 2
            }}
          >
            <form
              onSubmit={formik.handleSubmit}
            >
              <Grid container spacing={3}>
                <Grid item xs={12} md={4}>
                  <TextField
                    id='codigoMatricula'
                    helperText={String((formik.touched.codigoMatricula && formik.errors.codigoMatricula) ?? "")}
                    error={!!(formik.touched.codigoMatricula && formik.errors.codigoMatricula)}
                    fullWidth
                    InputProps={{
                      sx: {
                        ...rootInputStyles
                      }
                    }}
                    label='Código Matrícula Serviço Regular'
                    variant='outlined'
                    value={formik.values.codigoMatricula}
                    onChange={e => formik.setFieldValue('codigoMatricula', e.target.value)}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <TextField
                    id='dataReferencia'
                    fullWidth
                    helperText={String((formik.touched.dataReferencia && formik.errors.dataReferencia) ?? "")}
                    error={!!(formik.touched.dataReferencia && formik.errors.dataReferencia)}
                    type='date'
                    InputLabelProps={{ shrink: true }}
                    InputProps={{
                      sx: {
                        ...rootInputStyles
                      }
                    }}
                    label='Data de Referência'
                    variant='outlined'
                    value={formik.values.dataReferencia}
                    onChange={e => formik.setFieldValue('dataReferencia', e.target.value)}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <OutlinedInput
                    id='percentualDescontoEntrada'
                    type='number'
                    fullWidth
                    value={formik.values.percentualDescontoEntrada}
                    placeholder='Percentual Desconto (Entrada)'
                    endAdornment={<InputAdornment position='end'> <Percent /> </InputAdornment>}
                    onChange={e => {
                      const isValid = e.target.value && e.target.value !== '' ? true : false
                      const valor = isValid ? parseFloat(e.target.value) > 100 ? 100 : parseFloat(e.target.value) < 0 ? 0 : parseFloat(parseFloat(e.target.value).toFixed(1)) : null
                      formik.setFieldValue('percentualDescontoEntrada', valor)
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Stack direction='row' spacing={4}>
                    <Stack direction='column' spacing={2} sx={{flexGrow: 1 }}>
                      <Typography id="qntParcelaRegularEntrada" gutterBottom>
                        Quantidade de parcelas (Entrada)
                      </Typography>
                      <Slider
                        value={formik.values.qntParcelaRegularEntrada}
                        onChange={formik.handleChange}
                        aria-labelledby="qtd-parcelas"
                        name="qntParcelaRegularEntrada"
                        min={0}
                        max={12}
                        marks={Array.from(Array(13), (_, x) => x).map((parcela) => {
                          if (parcela === 0) {
                            return {
                              value: parcela,
                              label: 'Á Vista',
                            }
                          } else {
                            return {
                              value: parcela,
                              label: parcela + 'x',
                            }
                          }
                        })}
                        sx={{
                          width: '100%',
                          margin: '0.5rem 1rem !important'
                        }}
                      />
                    </Stack>
                    <Input
                      error={!!(formik.touched.qntParcelaRegularEntrada && formik.errors.qntParcelaRegularEntrada)}
                      value={formik.values.qntParcelaRegularEntrada}
                      size="small"
                      onChange={e => {
                        const isValid = e.target.value && e.target.value !== '' ? true : false
                        const valor = isValid ? parseInt(e.target.value) > 12 ? 12 : parseInt(e.target.value) < 0 ? 0 : parseInt(e.target.value) : null
                        formik.setFieldValue('qntParcelaRegularEntrada', valor)
                      }}
                      onBlur={formik.handleBlur}
                      name="qntParcelaRegularEntrada"
                      endAdornment={<InputAdornment position="end">X</InputAdornment>}
                      inputProps={{
                        step: 1,
                        min: 1,
                        max: 12,
                        type: 'number',
                        'aria-labelledby': 'qtd-parcelas',
                      }}
                    />
                  </Stack>
                </Grid>
                <Grid item xs={12}>
                  <Stack direction='row' spacing={4}>
                    <Stack direction='column' spacing={2} sx={{flexGrow: 1 }}>
                      <Typography id="qntParcelaRegular" gutterBottom>
                        Quantidade Parcela Regular
                      </Typography>
                      <Slider
                        value={formik.values.qntParcelaRegular}
                        onChange={formik.handleChange}
                        aria-labelledby="qtd-parcelaRegular"
                        name="qntParcelaRegular"
                        min={0}
                        max={12}
                        marks={Array.from(Array(13), (_, x) => x).map((parcela) => {
                          if (parcela === 0) {
                            return {
                              value: parcela,
                              label: 'Á Vista',
                            }
                          } else {
                            return {
                              value: parcela,
                              label: parcela + 'x',
                            }
                          }
                        })}
                        sx={{
                          width: '100%',
                          margin: '0.5rem 1rem !important'
                        }}
                      />
                    </Stack>
                    <Input
                      error={!!(formik.touched.qntParcelaRegular && formik.errors.qntParcelaRegular)}
                      value={formik.values.qntParcelaRegular}
                      size="small"
                      onChange={e => {
                        const isValid = e.target.value && e.target.value !== '' ? true : false
                        const valor = isValid ? parseInt(e.target.value) > 12 ? 12 : parseInt(e.target.value) < 0 ? 0 : parseInt(e.target.value) : null
                        formik.setFieldValue('qntParcelaRegular', valor)
                      }}
                      onBlur={formik.handleBlur}
                      name="qntParcelaRegular"
                      endAdornment={<InputAdornment position="end">X</InputAdornment>}
                      inputProps={{
                        step: 1,
                        min: 1,
                        max: 12,
                        type: 'number',
                        'aria-labelledby': 'qtd-parcelas',
                      }}
                    />
                  </Stack>
                </Grid>
                <Grid item xs={12}>
                  <Stack direction='row' spacing={4}>
                    <Stack direction='column' spacing={2} sx={{flexGrow: 1 }}>
                      <Typography id="qntParcelaMaterial" gutterBottom>
                        Quantidade Parcela Material
                      </Typography>
                      <Slider
                        value={formik.values.qntParcelaMaterial}
                        onChange={formik.handleChange}
                        aria-labelledby="qtd-parcelaMaterial"
                        name="qntParcelaMaterial"
                        min={0}
                        max={12}
                        marks={Array.from(Array(13), (_, x) => x).map((parcela) => {
                          if (parcela === 0) {
                            return {
                              value: parcela,
                              label: 'Á Vista',
                            }
                          } else {
                            return {
                              value: parcela,
                              label: parcela + 'x',
                            }
                          }
                        })}
                        sx={{
                          width: '100%',
                          margin: '0.5rem 1rem !important'
                        }}
                      />
                    </Stack>
                    <Input
                      error={!!(formik.touched.qntParcelaMaterial && formik.errors.qntParcelaMaterial)}
                      value={formik.values.qntParcelaMaterial}
                      size="small"
                      onChange={e => {
                        const isValid = e.target.value && e.target.value !== '' ? true : false
                        const valor = isValid ? parseInt(e.target.value) > 12 ? 12 : parseInt(e.target.value) < 0 ? 0 : parseInt(e.target.value) : null
                        formik.setFieldValue('qntParcelaMaterial', valor)
                      }}
                      onBlur={formik.handleBlur}
                      name="qntParcelaMaterial"
                      endAdornment={<InputAdornment position="end">X</InputAdornment>}
                      inputProps={{
                        step: 1,
                        min: 1,
                        max: 12,
                        type: 'number',
                        'aria-labelledby': 'qtd-parcelas',
                      }}
                    />
                  </Stack>
                </Grid>
                <Grid item xs={12} mt={2}>
                  <Typography variant='body2' sx={{ fontStyle: 'italic' }}>
                    Para fornecer transação á vista, inserir valor 0 na quantidade
                    de parcela
                  </Typography>
                  <Typography variant='body2'>
                    Caso um dos itens <strong>já existir</strong> no ERP. Por
                    favor se atentar a quantide de parcelas para encaminhar os
                    demais itens
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <FormControlLabel
                    label='Enviar Ficha'
                    control={
                      <Checkbox
                        name='ficha'
                        onChange={e => {
                          const fieldValue = {
                            ...formik.values.envioItems,
                            enviarFichaFinanceira: e.target.checked
                          }
                          formik.setFieldValue('envioItems', fieldValue)
                        }}
                      />
                    }
                  />
                  <FormControlLabel
                    label='Enviar Transações'
                    control={
                      <Checkbox
                        name='transacao'
                        onChange={e => {
                          const fieldValue = {
                            ...formik.values.envioItems,
                            enviarTransacaoFinanceira: e.target.checked
                          }
                          formik.setFieldValue('envioItems', fieldValue)
                        }}
                      />
                    }
                  />
                  <FormControlLabel
                    label='Enviar Serviço da Matrícula'
                    control={
                      <Checkbox
                        name='servicomatricula'
                        onChange={e => {
                          const fieldValue = {
                            ...formik.values.envioItems,
                            enviarServicoMatricula: e.target.checked
                          }
                          formik.setFieldValue('envioItems', fieldValue)
                        }}
                      />
                    }
                  />
                  <FormControlLabel
                    label='Enviar Ordem de Venda'
                    control={
                      <Checkbox
                        name='ov'
                        onChange={e => {
                          const fieldValue = {
                            ...formik.values.envioItems,
                            enviarOrdemVenda: e.target.checked
                          }
                          formik.setFieldValue('envioItems', fieldValue)
                        }}
                      />
                    }
                  />
                </Grid>
                <Stack
                  direction='row'
                  justifyContent='flex-end'
                  spacing={2}
                  sx={{ mt: 3, width: '100%' }}
                >
                  <Stack>
                    <Button
                      variant='contained'
                      color='primary'
                      type="submit"
                      startIcon={saving ? <CircularProgress color="primary" size={20} /> : <Save />}
                      disabled={saving}
                    >
                      Enviar
                    </Button>
                  </Stack>
                </Stack>
              </Grid>
            </form>
          </Paper>
        </Grow>
      </Box>
    </Container>
  )
}

export default ReplayIntegracaoRegularParcial