import React, {useEffect, useState} from "react";
import "./styles.css";
//@ts-ignore
import {PhotobookLog, PhotobookResponse} from "seb-graph-api-types/generated";
import {Matricula} from "../../components/MatriculaAutocompleteSelect/MatriculaAutocompleteSelect";
import {Unidade} from "../../components/UnidadeAutocompleteSelect/UnidadeAutocompleteSelect";
import {
  Box,
  Divider,
  ImageList,
  ImageListItem,
  ImageListItemBar,
  Menu,
  MenuItem,
  Skeleton,
  TextField
} from "@mui/material";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import SvgIcon from "@mui/material/SvgIcon";
import {CloseTwoTone, PersonTwoTone} from "@mui/icons-material";
import Avatar from "@mui/material/Avatar";
import Stack from "@mui/material/Stack";
import {gql, useQuery} from "@apollo/client";
import Icon from '@mdi/react';
import {
  mdiCalendarBlank,
  mdiCalendarMonth,
  mdiDownload,
  mdiExitRun,
  mdiFilePdfBox,
  mdiFormTextbox,
  mdiRun,
  mdiSignDirection,
  mdiSortAlphabeticalAscending,
  mdiSortAlphabeticalDescending,
  mdiSortAscending,
  mdiSortDescending,
  mdiViewWeek
} from '@mdi/js';
import Button from "@mui/material/Button";
import moment from "moment-timezone";
import {Options, Resolution, usePDF} from 'react-to-pdf';
import {styled} from "@mui/system";
import Dialog from "@mui/material/Dialog";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import DialogContent from "@mui/material/DialogContent";
import {useDialog} from "../../hooks/use-dialog";
import {Theme} from "@mui/material/styles/createTheme";
import {Scrollbar} from "../../components/scrollbar";
import withUnidadeMatricula from "../../hocs/withUnidadeMatricula";

const StyleDialog = styled(Dialog)(({theme}) => ({
  "& .MuiPaper-root": {
    overflowY: "visible",
  },
}));

const options: Options = {
  // default is `save`
  method: 'save',
  // default is Resolution.MEDIUM = 3, which should be enough, higher values
  // increases the image quality but also the size of the PDF, so be careful
  // using values higher than 10 when having multiple pages generated, it
  // might cause the page to crash or hang.
  resolution: Resolution.NORMAL,
  page: {
    // margin is in MM, default is Margin.NONE = 0
    margin: 1,
    // default is 'A4'
    format: 'A4',
    // default is 'portrait'
    orientation: 'portrait',
  },
  canvas: {
    // default is 'image/jpeg' for better size performance
    mimeType: 'image/jpeg',
    qualityRatio: 1,
  },
  // Customize any value passed to the jsPDF instance and html2canvas
  // function. You probably will not need this and things can break,
  // so use with caution.
  overrides: {
    // see https://artskydj.github.io/jsPDF/docs/jsPDF.html for more options
    pdf: {
      compress: true
    },
    // see https://html2canvas.hertzen.com/configuration for more options
    canvas: {
      useCORS: false
    }
  },
};

const periodFilter = {
  week: {
    text: "Esta semana",
    value: "week",
    icon: mdiViewWeek
  },
  month: {
    text: "Este mês",
    value: "month",
    icon: mdiCalendarMonth
  },
  year: {
    text: "Este ano",
    value: "year",
    icon: mdiCalendarBlank
  }
}

const orderByFilter = {
  date: {
    text: "Data",
    value: "date",
    icon: mdiCalendarBlank,
    child: {
      asc: {
        text: "Crescente",
        value: "asc",
        icon: mdiSortAscending
      },
      desc: {
        text: "Decrescente",
        value: "desc",
        icon: mdiSortDescending
      }
    }
  },
  direction: {
    text: "Direção",
    value: "direction",
    icon: mdiSignDirection,
    child: {
      asc: {
        text: "Entrada",
        value: "asc",
        icon: mdiRun
      },
      desc: {
        text: "Saída",
        value: "desc",
        icon: mdiExitRun
      }
    }
  },
  name: {
    text: "Nome",
    value: "name",
    icon: mdiFormTextbox,
    child: {
      asc: {
        text: "A-Z",
        value: "asc",
        icon: mdiSortAlphabeticalAscending
      },
      desc: {
        text: "Z-A",
        value: "desc",
        icon: mdiSortAlphabeticalDescending
      }
    }
  }
}

const QUERY_PHOTOBOOK = gql(`
    query Photobook($filter: PhotobookFilter) {
        photobook(filter: $filter) {
            data {
                id
                img
                date
                time
                direction
                name
                searchContextInfos
            }
        }
    }
`);

interface Props {
  Matricula: Matricula,
  Unidade: Unidade
}

function Photobook(props: Props) {
  const {
    Matricula,
    Unidade
  } = props;
  const [anchorPeriodEl, setAnchorPeriodEl] = React.useState(null);
  const [anchorOrderEl, setAnchorOrderEl] = React.useState(null);
  const openPeriod = Boolean(anchorPeriodEl);
  const openOrder = Boolean(anchorOrderEl);
  const [period, setPeriod] = useState(periodFilter.week);
  const [orderBy, setOrderBy] = useState(orderByFilter.date);
  const [orderByDirection, setOrderByDirection] = useState(orderBy.child.asc);
  const dialog = useDialog();
  const {loading, data} = useQuery<{
    photobook: PhotobookResponse
  }>(QUERY_PHOTOBOOK, {
    skip: !Matricula,
    variables: {
      filter: {
        contactId: Matricula?.idAluno,
        period: String(period.value).toUpperCase(),
      }
    }
  })
  //@ts-ignore
  const {toPDF, targetRef,} = usePDF({
    filename: `photobook_${Matricula?.idAluno}.pdf`,
    ...options
  });
  
  const handleClickPeriod = (event: any) => {
    setAnchorPeriodEl(event?.currentTarget);
  };
  
  const handleClickOrder = (event: any) => {
    setAnchorOrderEl(event?.currentTarget);
  }
  
  const handleClose = () => {
    setAnchorPeriodEl(null);
    setAnchorOrderEl(null);
  };
  
  const sortBy = (a: PhotobookLog, b: PhotobookLog) => {
    if (orderBy.value === "date") {
      return orderByDirection.value === "asc" ? a.date.localeCompare(b.date + b.time) : b.date.localeCompare(a.date + a.time);
    }
    if (orderBy.value === "direction") {
      // ordena por direction 1/0
      return orderByDirection.value === "desc" ? parseInt(a.direction) - parseInt(b.direction) : parseInt(b.direction) - parseInt(a.direction);
    }
    if (orderBy.value === "name") {
      return orderByDirection.value === "asc" ? a.name.localeCompare(b.name) : b.name.localeCompare(a.name);
    }
    return 0;
  }
  
  const filterByPeriod = (period: typeof periodFilter.week) => (item: PhotobookLog) => {
    if (period.value === "week") {
      const itemDate = new Date(item.date);
      const today = new Date();
      const first = today.getDate() - today.getDay();
      const last = first + 6;
      const firstDay = new Date(today.setDate(first)).toISOString().split('T')[0];
      const lastDay = new Date(today.setDate(last)).toISOString().split('T')[0];
      return itemDate >= new Date(firstDay) && itemDate <= new Date(lastDay);
    }
    if (period.value === "month") {
      const itemDate = new Date(item.date);
      const today = new Date();
      const firstDay = new Date(today.getFullYear(), today.getMonth(), 1).toISOString().split('T')[0];
      const lastDay = new Date(today.getFullYear(), today.getMonth() + 1, 0).toISOString().split('T')[0];
      return itemDate >= new Date(firstDay) && itemDate <= new Date(lastDay);
    }
    if (period.value === "year") {
      const itemDate = new Date(item.date);
      const today = new Date();
      const firstDay = new Date(today.getFullYear(), 0, 1).toISOString().split('T')[0];
      const lastDay = new Date(today.getFullYear(), 11, 31).toISOString().split('T')[0];
      return itemDate >= new Date(firstDay) && itemDate <= new Date(lastDay);
    }
    return data;
  }
  
  const results = (data?.photobook?.data || [])
    .filter(filterByPeriod(period))
    .sort(sortBy)
  
  const maxElementPerPage = 9;
  const pages = Math.ceil(results.length / maxElementPerPage);
  const pagesContent = Array.from({length: pages}, (_, index) => {
    const start = index * maxElementPerPage;
    const end = start + maxElementPerPage;
    return results.slice(start, end);
  });
  
  useEffect(() => {
    if (results.length === 0) {
      if ((data?.photobook?.data || []).filter(filterByPeriod(periodFilter.week)).length > 0 && period.value !== periodFilter.week.value) {
        setPeriod(periodFilter.week);
      } else if ((data?.photobook?.data || []).filter(filterByPeriod(periodFilter.month)).length > 0 && period.value !== periodFilter.month.value) {
        setPeriod(periodFilter.month);
      } else if ((data?.photobook?.data || []).filter(filterByPeriod(periodFilter.year)).length > 0 && period.value !== periodFilter.year.value) {
        setPeriod(periodFilter.year);
      }
    }
  }, [results]);
  
  return (
    <Box className="photobook">
      <StyleDialog
        fullWidth
        maxWidth="xl"
        onClose={dialog.handleClose}
        open={dialog.open}
      >
        <Stack
          alignItems="center"
          direction="row"
          justifyContent="space-between"
          spacing={3}
          sx={{
            px: 3,
            py: 2
          }}
        >
          <Typography variant="h6">
            Pré-visualização
          </Typography>
          <Stack
            direction="row"
            spacing={3}
          >
            <Button
              title="exportar"
              variant="contained"
              color="primary"
              disabled={results.length === 0}
              onClick={() => toPDF()}
              sx={{
                width: 40,
              }}
            >
              <Icon path={mdiFilePdfBox} size={1}/>
            </Button>
            <IconButton
              color="inherit"
              onClick={dialog.handleClose}
              sx={{
                width: "auto",
              }}
            >
              <CloseTwoTone/>
            </IconButton>
          </Stack>
        </Stack>
        <DialogContent
          sx={{
            backgroundColor: (theme: Theme) => theme.palette.grey[100],
          }}
        >
          <Scrollbar>
            <div ref={targetRef} className="book">
              {pagesContent.map((page, pageNumber) => (
                <div className="page">
                  {pageNumber === 0 && (
                    <div className="pageHeader">
                      <Stack
                        spacing={2}
                        direction="row"
                        sx={{
                          width: "100%"
                        }}
                      >
                        <TextField
                          fullWidth
                          label="Aluno(a)"
                          value={Matricula?.estudante_Name}
                          size="small"
                          variant="standard"
                        />
                        <TextField
                          fullWidth
                          label="Unidade"
                          value={Unidade?.nome}
                          size="small"
                          variant="standard"
                        />
                      </Stack>
                    </div>
                  )}
                  <div className="subpage">
                    <Stack
                      sx={{
                        width: "100%",
                      }}
                      direction="row"
                      justifyContent="center"
                      flexWrap="wrap"
                      spacing={0}
                    >
                      {(page || []).map((item) => (
                        <Box
                          key={item.img}
                          sx={{
                            p: 1,
                            pb: "5px"
                          }}
                        >
                          <Avatar
                            srcSet={item.img}
                            src={item.img}
                            alt={item.name}
                            sx={{
                              height: 200,
                              width: 150,
                              borderRadius: '10px',
                            }}
                          >
                            <SvgIcon>
                              <PersonTwoTone/>
                            </SvgIcon>
                          </Avatar>
                          <ImageListItemBar
                            title={item.name}
                            subtitle={(
                              <Stack
                                spacing={1}
                              >
                              <span>
                                {parseInt(item.direction) !== 1 ? <Icon path={mdiRun} size="16px"/> :
                                  <Icon path={mdiExitRun}
                                        size="14px"/>}{parseInt(item.direction) !== 1 ? "Entrada" : "Saída"}
                              </span>
                                <span>
                               {<Icon
                                 path={mdiCalendarBlank}
                                 size="16px"/>} {moment(item.date).tz("America/Sao_Paulo").format("DD/MM/YYYY")} - {moment(`${item.date}T${item.time}`).tz("America/Sao_Paulo").subtract(3, "hour").format("HH:mm:ss")}
                              </span>
                              </Stack>
                            )}
                            position="below"
                          />
                        </Box>
                      ))}
                    </Stack>
                  </div>
                  <span className="pageNumber">{pageNumber + 1}</span>
                </div>
              ))}
            </div>
          </Scrollbar>
        </DialogContent>
      </StyleDialog>
      
      
      <CardHeader
        title="Photobook"
        subheader="Passagens recentes pelas portarias"
        action={(
          <Button
            title="exportar"
            variant="contained"
            color="primary"
            disabled={results.length === 0}
            onClick={() => dialog.handleOpen()}
            sx={{
              width: 40,
            }}
          >
            <Icon path={mdiDownload} size={1}/>
          </Button>
        )}
      />
      
      <Divider/>
      
      <CardContent sx={{pt: 1}}>
        <Stack
          sx={{pb: 1}}
          direction="row"
          spacing={1}
        >
          <Stack
            direction="row"
          >
            <Box>
              <Button
                id="period-filter-button"
                aria-controls={openPeriod ? 'period-filter-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={openPeriod ? 'true' : undefined}
                onClick={handleClickPeriod}
                variant="text"
                color="primary"
                startIcon={<SvgIcon><Icon path={period.icon} size="16px"/></SvgIcon>}
              >
                {period.text}
              </Button>
              <Menu
                id="period-filter-menu"
                aria-labelledby="period-filter-button"
                anchorEl={anchorPeriodEl}
                open={openPeriod}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
              >
                {Object.values(periodFilter).map((item) => (
                  <MenuItem
                    key={item.value}
                    onClick={() => {
                      setPeriod(item);
                      handleClose();
                    }}
                  >
                    <Stack
                      direction="row"
                      spacing={1}
                    >
                      <SvgIcon><Icon path={item.icon} size="16px"/></SvgIcon>
                      <span>
                      {item.text} ({(data?.photobook?.data || []).filter(filterByPeriod(item)).length})
                    </span>
                    </Stack>
                  </MenuItem>
                ))}
              </Menu>
            </Box>
            
            <Box>
              <Button
                id="order-filter-button"
                aria-controls={openOrder ? 'order-filter-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={openOrder ? 'true' : undefined}
                onClick={handleClickOrder}
                variant="text"
                color="primary"
                startIcon={<SvgIcon><Icon path={orderBy.icon} size="16px"/></SvgIcon>}
              >
                {orderBy.text}
              </Button>
              <Menu
                id="order-filter-menu"
                aria-labelledby="order-filter-button"
                anchorEl={anchorOrderEl}
                open={openOrder}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
              >
                {Object.values(orderByFilter).map((item) => (
                  <MenuItem
                    key={item.value}
                    onClick={() => {
                      setOrderBy(item);
                      setOrderByDirection(item.child.asc);
                      handleClose();
                    }}
                  >
                    <Stack
                      direction="row"
                      spacing={1}
                    >
                      <SvgIcon><Icon path={item.icon} size="16px"/></SvgIcon>
                      <span>
                      {item.text}
                    </span>
                    </Stack>
                  </MenuItem>
                ))}
              </Menu>
            </Box>
            
            <Box>
              <Button
                id="direction-filter-button"
                onClick={() => {
                  setOrderByDirection(orderByDirection.value === "asc" ? orderBy.child.desc : orderBy.child.asc);
                }}
                variant="text"
                color="primary"
                startIcon={<SvgIcon><Icon path={orderByDirection.icon} size="16px"/></SvgIcon>}
              >
                {orderByDirection.text}
              </Button>
            </Box>
          </Stack>
        </Stack>
        
        <Box>
          {loading && (
            <Stack
              sx={{
                width: "100%",
                height: "100%",
              }}
              direction="row"
              justifyContent="center"
              flexWrap="wrap"
              spacing={0}
            >
              {Array.from(new Array(12)).map((_, index) => (
                <Box
                  key={index}
                  sx={{
                    p: 1
                  }}
                >
                  <Skeleton
                    animation="wave"
                    width={150}
                    height={200}
                    sx={{
                      transform: 'none',
                      borderRadius: '10px',
                      height: 200,
                      width: 150,
                    }}
                  />
                  <ImageListItemBar
                    title="Carregando..."
                    subtitle="Carregando..."
                    position="below"
                  />
                </Box>
              ))}
            </Stack>
          )}
          
          {(!loading && results.length !== 0) && (
            <Scrollbar>
              <Stack
                sx={{
                  width: "100%",
                  height: "100%",
                }}
                direction="row"
                justifyContent="center"
                flexWrap="wrap"
                spacing={0}
              >
                {(results || []).map((item) => (
                  <Box
                    key={item.img}
                    sx={{
                      p: 1
                    }}
                  >
                    <Avatar
                      srcSet={item.img}
                      src={item.img}
                      alt={item.name}
                      sx={{
                        height: 200,
                        width: 150,
                        borderRadius: '10px',
                      }}
                    >
                      <SvgIcon>
                        <PersonTwoTone/>
                      </SvgIcon>
                    </Avatar>
                    <ImageListItemBar
                      title={item.name}
                      subtitle={(
                        <Stack
                          spacing={1}
                        >
                    <span>
                      {parseInt(item.direction) !== 1 ? <Icon path={mdiRun} size="16px"/> :
                        <Icon path={mdiExitRun} size="14px"/>}{parseInt(item.direction) !== 1 ? "Entrada" : "Saída"}
                    </span>
                          <span>
                     {<Icon path={mdiCalendarBlank}
                            size="16px"/>} {moment(item.date).tz("America/Sao_Paulo").format("DD/MM/YYYY")} - {moment(`${item.date}T${item.time}`).tz("America/Sao_Paulo").subtract(3, "hour").format("HH:mm:ss")}
                   </span>
                        </Stack>
                      )}
                      position="below"
                    />
                  </Box>
                ))}
              </Stack>
            </Scrollbar>
          )}
          {(!loading && results.length === 0) && (
            <Stack
              direction="row"
              justifyContent="center"
              alignItems="center"
              sx={{
                height: 450,
                width: "100%"
              }}
            >
            <span>
              Nenhum registro encontrado
            </span>
            </Stack>
          )}
        </Box>
      </CardContent>
    </Box>
  )
}

export default withUnidadeMatricula(
  Photobook,
  "Photobook"
)
